<template>
    <div id="order-services">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadOrders
    :TitlePage="$route.name"
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('Services')"
    PathPageTo="الخدمة"
    pathFrom="/"
    pathCenter="/services"
    />
    <div class="container">
    <Order />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Service Order',
  components: {
    HeadOrders: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Global/HeadOrders.vue') ),
    Order: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Services/Order/Order.vue') ),
  },
  }
  </script>

