<template>
<PriceOffer v-if="this.$store.state.showOfferPrice == true"  />
    <div class="row">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Service_details') }}</h2>
                <button @click="this.$store.state.showOfferPrice = true" class="btn-main">{{ $t('Show_Price') }}</button>
                {{  }}
            </div>
        </div>
        <div class="col-md-12" v-if="OrderInfo">
            <div class="item">
                <div class="title">{{ OrderInfo.title }}</div>
            </div>
        </div>
        <div class="col-md-6" v-for="(item,index) in OrderInfo?.attributes" :key="index">
            <div class="item">
                <div class="title">{{ item.name }}</div>
                <div class="value" v-if="item.type == 'text'">{{ attribute_values[index].attribute_value }}</div>
                <div class="value" v-if="item.type == 'select'">{{ attribute_values[index].attribute_value_id }}</div>
                <div class="value" v-if="item.type == 'checkbox'">{{ attribute_values[index].attribute_values_ids }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
    data() {
        return {
            index: 1,
        };
    },
    props:{
        OrderInfo:Object,
        attribute_values:Array,
    },
    components:{
        PriceOffer: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Order/Tabs/PriceOffer.vue') ),
    }
}
</script>
