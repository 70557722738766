<template>
  <div class="m-p">  
      <MyAccount />
  </div>
   
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Account',
components: {
  MyAccount: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/MyAccount.vue') ),
},
}
</script>