<template>
    <button class="btn-main" :style="style">
        {{ title }}
    </button>
 </template>
 <script>
 export default {
    props: {
    title: String,
    TextColor: String,
    hovercolor: String,
    BackgroundColor: String,
    hoverBackgroundColor: String,
    Border: String,
    hoverBorder: String,
  },
  data() {
    return {
      style: {
        '--color': this.TextColor,
        '--hovercolor': this.hovercolor,
        '--BackgroundColor': this.BackgroundColor,
        '--hoverBackgroundColor': this.hoverBackgroundColor,
        '--Border': this.Border,
        '--hoverBorder': this.hoverBorder,
      },
    };
  }
 
 };
 </script>
 <style lang="scss" scoped>
    .btn-main{
        width: 125px;
        height: 52px;
        padding: 15px; 
        border-radius: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        transition: .5s;
        border: 1px solid var(--Border);
        color: var(--color);
        background-color: var(--BackgroundColor);
        &:hover{
            color: var(--hovercolor);
            background-color: var(--hoverBackgroundColor);
            border: 1px solid var(--hoverBorder);
        }
    }
</style>

 