<template>
  <PriceOffer v-if="this.$store.state.showOfferPrice == true"  />
  <rateModal v-if="this.$store.state.showRate == true"  />
    <div class="row">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Service_details') }}</h2>
                <div>
                  <button @click="this.$store.state.showOfferPrice = true" class="btn-main">{{ $t('Show_Price') }}</button>
                  <button @click="this.$store.state.showRate = true" class="btn-main" style="margin-right:10px">{{ $t('Rate') }}</button>
                </div>

                {{  }}
            </div>
        </div>
        <div class="nested-title mb-3">
            {{ this.$i18n.locale == 'ar' ?
             `تفاصيل الباقة الخاصة بشركتك`
             :
             `Details of your company's package`
            }}
        </div>
        <div class="col-md-6 package-item mb-3" >
            <div class="title">
                الباقة الفضية
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseone`" aria-expanded="true"
                        :aria-controls="`collapseone`">
                        <svg style="margin-inline-end: 12px;" width="18" height="14" viewBox="0 0 18 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.1136 13.725L0.263593 7.62349C-0.0878643 7.25692 -0.0878643 6.66256 0.263593 6.29596L1.53636 4.96843C1.88781 4.60182 2.4577 4.60182 2.80915 4.96843L6.75 9.07869L15.1908 0.274927C15.5423 -0.0916425 16.1122 -0.0916425 16.4636 0.274927L17.7364 1.60246C18.0879 1.96903 18.0879 2.56338 17.7364 2.92998L7.3864 13.7251C7.0349 14.0916 6.46506 14.0916 6.1136 13.725Z"
                                fill="#0A3041" />
                        </svg>
                        تسجيل بمنصة قوي
                        <svg class="plus" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.8571 9.14286H9.14286V14.8571C9.14286 15.1602 9.02245 15.4509 8.80812 15.6653C8.59379 15.8796 8.30311 16 8 16C7.6969 16 7.40621 15.8796 7.19188 15.6653C6.97755 15.4509 6.85714 15.1602 6.85714 14.8571V9.14286H1.14286C0.839753 9.14286 0.549063 9.02245 0.334735 8.80812C0.120408 8.59379 0 8.30311 0 8C0 7.6969 0.120408 7.40621 0.334735 7.19188C0.549063 6.97755 0.839753 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.839753 6.97755 0.549062 7.19188 0.334735C7.40621 0.120407 7.6969 0 8 0C8.30311 0 8.59379 0.120407 8.80812 0.334735C9.02245 0.549062 9.14286 0.839753 9.14286 1.14286V6.85714H14.8571C15.1602 6.85714 15.4509 6.97755 15.6653 7.19188C15.8796 7.40621 16 7.6969 16 8C16 8.30311 15.8796 8.59379 15.6653 8.80812C15.4509 9.02245 15.1602 9.14286 14.8571 9.14286Z"
                                fill="#0A3041" />
                        </svg>
                        <svg class="close" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0406 11.6571L6 7.61648L1.95939 11.6571C1.74506 11.8714 1.45437 11.9918 1.15127 11.9918C0.848163 11.9918 0.557474 11.8714 0.343146 11.6571C0.128819 11.4428 0.00841052 11.1521 0.00841086 10.849C0.00841052 10.5459 0.128819 10.2552 0.343147 10.0408L4.38376 6.00023L0.343146 1.95962C0.128818 1.74529 0.00841078 1.4546 0.00841048 1.1515C0.00841085 0.848395 0.128818 0.557705 0.343146 0.343378C0.557473 0.12905 0.848163 0.00864293 1.15127 0.0086429C1.45437 0.00864286 1.74506 0.129051 1.95939 0.343378L6 4.38399L10.0406 0.343378C10.2549 0.129051 10.5456 0.00864248 10.8487 0.00864252C11.1518 0.00864255 11.4425 0.12905 11.6569 0.343378C11.8712 0.557705 11.9916 0.848395 11.9916 1.1515C11.9916 1.4546 11.8712 1.74529 11.6569 1.95962L7.61624 6.00023L11.6569 10.0408C11.8712 10.2552 11.9916 10.5459 11.9916 10.849C11.9916 11.1521 11.8712 11.4428 11.6569 11.6571C11.4425 11.8714 11.1518 11.9918 10.8487 11.9918C10.5456 11.9918 10.2549 11.8714 10.0406 11.6571Z"
                                fill="#0A3041" />
                        </svg>
                    </button>
                </h2>
                <div :id="`collapseone`" class="accordion-collapse collapse " aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <ul>
                            <li>تسجيل بمنصة قوى</li>
                            <li>رخص العمل</li>
                            <li>إدارة المهن</li>
                            <li>إصدار شهادة السعودة</li>
                            <li>إدارة عقود العمل</li>
                            <li>تأشيرات العمل الفوري</li>
                            <li>نقل الخدمات</li>
                            <li>تجديد اشتراك بمنصة قوى</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapsetwo`" aria-expanded="true"
                        :aria-controls="`collapsetwo`">
                        <svg style="margin-inline-end: 12px;" width="18" height="14" viewBox="0 0 18 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.1136 13.725L0.263593 7.62349C-0.0878643 7.25692 -0.0878643 6.66256 0.263593 6.29596L1.53636 4.96843C1.88781 4.60182 2.4577 4.60182 2.80915 4.96843L6.75 9.07869L15.1908 0.274927C15.5423 -0.0916425 16.1122 -0.0916425 16.4636 0.274927L17.7364 1.60246C18.0879 1.96903 18.0879 2.56338 17.7364 2.92998L7.3864 13.7251C7.0349 14.0916 6.46506 14.0916 6.1136 13.725Z"
                                fill="#0A3041" />
                        </svg>
                        رخص  العمل
                        <svg class="plus" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.8571 9.14286H9.14286V14.8571C9.14286 15.1602 9.02245 15.4509 8.80812 15.6653C8.59379 15.8796 8.30311 16 8 16C7.6969 16 7.40621 15.8796 7.19188 15.6653C6.97755 15.4509 6.85714 15.1602 6.85714 14.8571V9.14286H1.14286C0.839753 9.14286 0.549063 9.02245 0.334735 8.80812C0.120408 8.59379 0 8.30311 0 8C0 7.6969 0.120408 7.40621 0.334735 7.19188C0.549063 6.97755 0.839753 6.85714 1.14286 6.85714H6.85714V1.14286C6.85714 0.839753 6.97755 0.549062 7.19188 0.334735C7.40621 0.120407 7.6969 0 8 0C8.30311 0 8.59379 0.120407 8.80812 0.334735C9.02245 0.549062 9.14286 0.839753 9.14286 1.14286V6.85714H14.8571C15.1602 6.85714 15.4509 6.97755 15.6653 7.19188C15.8796 7.40621 16 7.6969 16 8C16 8.30311 15.8796 8.59379 15.6653 8.80812C15.4509 9.02245 15.1602 9.14286 14.8571 9.14286Z"
                                fill="#0A3041" />
                        </svg>
                        <svg class="close" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0406 11.6571L6 7.61648L1.95939 11.6571C1.74506 11.8714 1.45437 11.9918 1.15127 11.9918C0.848163 11.9918 0.557474 11.8714 0.343146 11.6571C0.128819 11.4428 0.00841052 11.1521 0.00841086 10.849C0.00841052 10.5459 0.128819 10.2552 0.343147 10.0408L4.38376 6.00023L0.343146 1.95962C0.128818 1.74529 0.00841078 1.4546 0.00841048 1.1515C0.00841085 0.848395 0.128818 0.557705 0.343146 0.343378C0.557473 0.12905 0.848163 0.00864293 1.15127 0.0086429C1.45437 0.00864286 1.74506 0.129051 1.95939 0.343378L6 4.38399L10.0406 0.343378C10.2549 0.129051 10.5456 0.00864248 10.8487 0.00864252C11.1518 0.00864255 11.4425 0.12905 11.6569 0.343378C11.8712 0.557705 11.9916 0.848395 11.9916 1.1515C11.9916 1.4546 11.8712 1.74529 11.6569 1.95962L7.61624 6.00023L11.6569 10.0408C11.8712 10.2552 11.9916 10.5459 11.9916 10.849C11.9916 11.1521 11.8712 11.4428 11.6569 11.6571C11.4425 11.8714 11.1518 11.9918 10.8487 11.9918C10.5456 11.9918 10.2549 11.8714 10.0406 11.6571Z"
                                fill="#0A3041" />
                        </svg>
                    </button>
                </h2>
                <div :id="`collapsetwo`" class="accordion-collapse collapse " aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <ul>
                            <li>تسجيل بمنصة قوى</li>
                            <li>رخص العمل</li>
                            <li>إدارة المهن</li>
                            <li>إصدار شهادة السعودة</li>
                            <li>إدارة عقود العمل</li>
                            <li>تأشيرات العمل الفوري</li>
                            <li>نقل الخدمات</li>
                            <li>تجديد اشتراك بمنصة قوى</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
           <div class="item d-flex align-items-center justify-content-between">
            <div class="title">اصدار سجل تجاري</div>
            <div class="value mt-0" style="font-size: 14px;"> 259 2458421</div>
           </div>
           <div class="item d-flex align-items-center justify-content-between">
            <div class="title">تاريخ الاصدار</div>
            <div class="value mt-0" style="font-size: 14px;">22 فبراير 2022</div>
           </div>
           <div class="item d-flex align-items-center justify-content-between">
            <div class="title">تاريخ الانتهاء</div>
            <div class="value mt-0" style="font-size: 14px;">25 فبراير 2022</div>
           </div>
           <div class="item d-flex align-items-center justify-content-between">
            <div class="title">القسط الشهري</div>
            <div class="value mt-0" style="font-size: 14px;">3600 ر.س</div>
           </div>

        </div>
        <div class="nested-title mb-3">
            {{ this.$i18n.locale == 'ar' ?
             `تفاصيل طلب الباقة`
             :
             `Package order details`   
            }}
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`عدد الموظفين`:`Number of Employees`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">124 موظف</div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`عدد السجلات`:`Number of Records`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">124 موظف</div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`خطة الدفع`:`Payment plan`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">124 موظف</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`الإجمالي`:`Total`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">700.00 ريال</div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`الخصم `:`Discount`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">700.00 ريال</div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`الضريبة`:`Tax`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">700.00 ريال</div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="title">{{ this.$i18n.locale == 'ar' ?`المبلغ الشهري`:`Monthly Amount`}}</div>
                    <div class="value mt-0" style="font-size: 14px;">700.00 ريال</div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="item">
                <div class="title">هذا النص هو مثال لسؤال يوضع في المكان يوضح نص معين ؟</div>
                <div class="value">هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">هذا النص هو مثال لنص سؤال</div>
                <div class="value">000 000 000</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">هذا النص هو مثال لنص سؤال</div>
                <div class="value">نص مثال لاجابة عن سؤال الصندوق</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">هذا النص هو مثال لنص سؤال</div>
                <div class="value">نص مثال لاجابة عن سؤال الصندوق</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">هذا النص هو مثال لنص سؤال</div>
                <div class="value">نص مثال لاجابة عن سؤال الصندوق</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">هذا النص هو مثال لنص سؤال</div>
                <div class="value">نص مثال لاجابة عن سؤال الصندوق</div>
            </div>
        </div>

    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
    data() {
        return {
            index: 1,
        };
    },

    components:{
        PriceOffer: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Order/Tabs/PriceOffer.vue') ),
        rateModal:defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Order/Tabs/RateModal.vue') ),
    }

}
</script>
