<template>
    <div class="step1 step2 step3">
        <div class="row mt-5">
              <div :class="[item.type == 'text' || item.type == 'select' ? 'col-md-6':'col-md-12']" 
              class=" mb-3" v-for="(item,index) in Attributes" :key="index">
                <div v-if="item.type == 'text'">
                  <label class="title mb-1">{{ item.name }}</label>
                  <input @keydown="handleKeydown" v-model="$store.state.serviceOrder.step3.atrrbuites[index].attribute_value" 
                  :type="item.type" :placeholder="item.name">
                </div>
                <div v-if="item.type == 'select'">
                  <label class="title mb-1">{{ item.name }}</label>
                  <select @keydown="handleKeydown" v-model="$store.state.serviceOrder.step3.atrrbuites[index].attribute_value_id" :name="item.name" id="">
                    <option :value="j.id" v-for="j in item.attribute_values">
                    {{ j.value }}
                    </option>
                  </select>
                </div>
            
                <div v-if="item.type == 'file'">
                <label class="imageId" for="imageId">
                <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.45645 12.6206C4.46713 13.5696 1.5 17.157 1.5 21.4375C1.5 26.4426 5.55656 30.5 10.5606 30.5C11.419 30.5 12.2495 30.3806 13.0364 30.1575M30.5435 12.6206C34.5329 13.5696 37.5 17.157 37.5 21.4375C37.5 26.4426 33.4435 30.5 28.4394 30.5C27.581 30.5 26.7505 30.3806 25.9636 30.1575M30.4939 12.375C30.4939 6.3689 25.626 1.5 19.6212 1.5C13.6164 1.5 8.74847 6.3689 8.74847 12.375M13.3382 19.5173L19.6213 13.2122L26.0832 19.6252M19.6212 28.6873V16.4184"
                        stroke="#225476" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>
                    {{ $t('List your files') }}
                    <br>
                   {{ item.validation_rules[2].slice(5,item.validation_rules[2].length) }}
                </span>
            </label>
            <input accept="image/*,application/pdf" type="file" id="imageId" for="imageId" hidden
                @change="setfileIndex(index),SetimageId()">
                </div>
              </div>
            </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      imageId:[],
    }
  },
  props:['Attributes'],

  methods:{
    handleKeydown(event) {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent the default tab action
      }
    },
    SetimageId(e){
      console.log(event.target.files);
      this.imageId.push(event.target.files[0])
    },
    downloadFile(file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      URL.revokeObjectURL(url);
    },

    
  }

};
</script>