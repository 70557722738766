<template>
      <div class="slider-component">
       <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <img class="w-100" :src="require(`@/assets/img/services-page${Imag}`)" />
        </div>
        <div class="col-md-6">
          <swiper class="swiper"  :pagination="pagination"
        :space-between="20" 
        :speed="1000" >
          <swiper-slide >
           <h3>{{ Title }}</h3>
           <h5>{{ Title_Seconde }}</h5>
          <p>
            {{ this.$i18n.locale == 'ar' ?
            `
            نقدم الدعم الكامل للمستثمرين سواء موطنين أو خليجيين أو أجانب الراغبين بدخول السوق السعودي، مع توجيهات متخصصة لتأسيس الشركات، شاملة الاستشارات القانونية والإدارية والمالية ، التسجيل في الجهات الحكومية ، التأمينات الاجتماعية ، استخراج التأشيرات المهنية والتوظيف ، واستخراج التراخيص اللازمة.
            خبراؤنا يوجهونك في كل خطوة لضمان التأسيس الناجح وفهم الإجراءات اللازمة بعد التأسيس.
            `
          :
          `We provide full support to investors, whether local, Gulf or foreign, who wish to enter the Saudi market, with specialized guidance for establishing companies, including legal, administrative and financial consultations, registration in government agencies, social insurance, obtaining professional and employment visas, and obtaining the necessary licenses.
          Our experts guide you through every step to ensure successful incorporation and understand the necessary post-incorporation procedures.
          ` }}
          </p>
          </swiper-slide>
          <swiper-slide >
           <h3>{{ Title }}</h3>
           <h5>{{ Title_Seconde }}</h5>
          <p>
            {{ this.$i18n.locale == 'ar' ?
           `
           نقدم دعمًا شاملًا للراغبين بتأسيس أو توسيع شركاتهم في السعودية، سواء كانوا مواطنين أو أجانب أو شركات وطنية أو خليجية. نوفر إرشادات حول الإجراءات الإدارية والتنظيمية، بما في ذلك التسجيل في جميع المنصات الحكومية اللازمة ، والحصول على السجلات و التراخيص اللازمة . خبراؤنا مستعدون لإرشادك خلال عملية التأسيس بكفاءة، مع مراعاة الهيكل القانوني الأنسب لنشاطك التجاري. نسهل عليك استكمال جميع الخطوات اللازمة لنجاح عملك في السعودية.
            `
          :
          `We provide comprehensive support to those wishing to establish or expand their companies in Saudi Arabia, whether they are citizens, foreigners, or national or Gulf companies. We provide guidance on administrative and regulatory procedures, including registering on all necessary government platforms, and obtaining the necessary records and licenses. Our experts are ready to guide you through the incorporation process efficiently, taking into account the legal structure that best suits your business. We make it easy for you to complete all the steps necessary for the success of your business in Saudi Arabia.
          ` }}
          </p>
          </swiper-slide>
          <swiper-slide >
           <h3>{{ Title }}</h3>
           <h5>{{ Title_Seconde }}</h5>
          <p>
            {{ this.$i18n.locale == 'ar' ?
            `
"إتمام" هي متخصصة في تأسيس الشركات وحلول الأعمال  يسعى فريقنا إلى أن يكون ديناميكيًا ومبتكرًا ومتميزًا في صناعة الأعمال التجارية . نحن نقود الطريق نحو الابتكار والإبداع مع التركيز بشكل كبير على تجربة العملاء وتنفيذ الخدمات. نحن نقدم استشارات وخدمات وبنية تحتية مخصصة لتسريع عملية التأسيس في السعودية ودول الخليج ، ومساعدة عملائنا في مواءمة أولويات أعمالهم مع الرؤية المستقبلية.                           `
          :
          `
          "Etmaam" is specialized in establishing companies and business solutions. Our team seeks to be dynamic, innovative and distinguished in the business industry. We lead the way in innovation and creativity with a keen focus on customer experience and service execution. We provide customized consulting, services and infrastructure to accelerate the establishment process in Saudi Arabia and the Gulf countries, and help our clients align their business priorities with the future vision.
          ` }}
          </p>
          </swiper-slide>
        </swiper>
        </div>
       </div>
      </div>
</template>
<script>
export default {
  data() {
    return {
     pagination:{
			clickable: true,
      renderBullet: function (index, className) {
          return '<span class="' + className + '">0'+(index+1)+'</span>';
        },
    }
    };
  },
props:{
  Imag:String,
  Title:String,
  Title_Seconde:String,
}

}
</script>