<template>
  <div class="geo">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item active" role="presentation">
        <button data-index="location-1" class="nav-link py-3 active" id="v-pills-location-1-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-location-1" type="button" role="tab" aria-controls="v-pills-location-1"
          aria-selected="true">
          <span class=" fs-sm-4">
            {{ this.$i18n.locale == 'ar' ? 'الرياض':'Riyadh' }}
          </span>
        </button>
      </li>
      <li class="nav-item " role="presentation">
        <button data-index="location-2" class="nav-link py-3 " id="v-pills-location-2-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-location-2" type="button" role="tab" aria-controls="v-pills-location-2"
          aria-selected="true">
          <span class=" fs-sm-4">
            {{ this.$i18n.locale == 'ar' ? 'الخبر':'Al Khobar' }}
          </span>
        </button>
      </li>
      <li class="nav-item " role="presentation">
        <button data-index="location-3" class="nav-link py-3 " id="v-pills-location-3-tab" data-bs-toggle="pill"
          data-bs-target="#v-pills-location-3" type="button" role="tab" aria-controls="v-pills-location-3"
          aria-selected="true">
          <span class=" fs-sm-4">
            {{ this.$i18n.locale == 'ar' ? 'محايل عسير':'Mahayel Asir' }}
          </span>
        </button>
      </li>
    </ul>
    <div class="tab-content mt-3" id="pills-tabContent">
      <div class="tab-pane fade show active" id="v-pills-location-1" role="tabpanel"
        aria-labelledby="v-pills-location-1-tab">
        <div id="map">
          <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="Riyadh_location"></iframe>
        </div>
      </div>
      <div class="tab-pane fade show " id="v-pills-location-2" role="tabpanel" aria-labelledby="v-pills-location-2-tab">
        <div id="map">
                            <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="Khobar_location"></iframe>
                        </div>
      </div>
      <div class="tab-pane fade show " id="v-pills-location-3" role="tabpanel" aria-labelledby="v-pills-location-3-tab">
        <div id="map">
                            <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="Muhayil_location"></iframe>
                        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
 props:["Riyadh_location","Khobar_location","Muhayil_location"]
}
</script>