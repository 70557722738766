<template>
    <div class="bank-accounts">
        <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('BankAccounts')" />
        <div class="container">
            <div class="main-title">
                <p>
                {{ this.$i18n.locale == 'ar' ?
                    `حساباتنا البنكية`
                    :
                    `our terms and conditions`
                }}
                </p>
            </div>

            <div class="banner">
                <div class="row">
                    <div class="col-sm-6 text-content">
                        <h1 class="title">
                            {{ this.$i18n.locale == 'ar' ?
                                `قم بالاطلاع على حساباتنا البنكية`
                                :
                                `View our terms and conditions`
                            }}
                        </h1>
                        <p class="mt-3">
                            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص
                            العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف
                            التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى
                        </p>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-center">
                        <img src="@/assets/img/bank/bank-1.png" alt="UsingPolicy">
                    </div>
                </div>
            </div>

            <div class="row mt-5 info">
                <div class="col-lg-4 col-md-6 col-sm-6 " v-for="item in BankAccounts" :key="item.id">
                    <div class="item mb-5">
                        <div class="img">
                            <img :src="item.image" style=" width: 110px; height: 110px; border-radius: 10px;" :alt="item.alt">
                        </div>
                        <div class="content bank-info">
                            <div class="row">

                                <div class="first-value">

                                    {{ this.$i18n.locale == 'ar' ?
                                        `اسم البنك`
                                        :
                                        `Bank Name`
                                    }}
                                </div>
                                <div class="value">{{ item.name }} </div>
                            </div>
                            <div class="row">
                                <div class="title">
                                    {{ this.$i18n.locale == 'ar' ?
                                        `رقم الايبان`
                                        :
                                        `iban_number`
                                    }}
                                </div>
                                <div class="d-flex value">
                                    {{ item.iban_number }}
                                    <img class="svc-copy" src="@/assets/img/bank/svc4.png" @click="copyToClipboard(item.iban_number)">


                                </div>
                            </div>
                            <div class="row">
                                <div class="title">
                                    {{ this.$i18n.locale == 'ar' ?
                                        `رقم الحساب`
                                        :
                                        `account_number`
                                    }}
                                </div>
                                <div class="d-flex value">
                                    <span>{{ item.account_number }}</span>
                                    <img class="svc-copy" src="@/assets/img/bank/svc4.png"
                                        @click="copyToClipboard(item.account_number)">
                                </div>
                            </div>
                            <div class="row">
                                <div class="title">
                                    {{ this.$i18n.locale == 'ar' ?
                                        `صاحب الحساب`
                                        :
                                        `owner`
                                    }}
                                </div>
                                <div class="value">{{ item.owner }} </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import { notify } from "@kyvg/vue3-notification";

export default {
    name: 'Faqs Page',
    data() {
        return {
            BankAccounts: [],
        }
    },
    components: {
        HeadOrders: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Global/HeadOrders.vue')),
    },

    mounted() {
        this.$store.dispatch('GetBankAccounts').then(r => {
            this.BankAccounts = this.$store.state.BankAccounts;
        });
    },
    methods:{
        copyToClipboard(text) {
      // Create a temporary textarea element
      const tempInput = document.createElement("textarea");
      tempInput.value = text;
      document.body.appendChild(tempInput);

      // Select the text in the textarea
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected text to the clipboard
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(tempInput);

        notify({
                type: "success",
                title:this.$i18n.locale == 'ar' ? 'تم النسخ بنجاح' :'Text copied successfully' ,
                text: text,
            });
    }
    }
}
</script>