<template>
  <div class="hero" >
      <img class="cover"  :src="image" alt="">
    <div class="container padding-heder info" >
      <h1 data-aos="fade-up"> {{main_title }}</h1>
      <h2 data-aos="fade-up">{{secoundary_title}}</h2>
      <p data-aos="fade-up">{{hero_description}}</p>
    </div>
     <div class="container-fluid info">
      <div class="row">
        <div class="col-md-2"></div>
        <div  :class="['guide','col-md-10',{'animate__animated': isHovered, 'animate__pulse': isHovered}]">
          <div class="row">
            <div class="col-md-4 item">
             <span v-html="hero_card_1_photo"></span>
              <div>
                <h2 class="title">
                  {{ hero_card_1_title }}
                </h2>
                <p class="disc">
                  {{ hero_card_1_description }}
                </p>
              </div>
            </div>
            <div class="col-md-4 item">
             <span v-html="hero_card_2_photo"></span>
              <div>
                <h2 class="title">
                  {{ hero_card_2_title }}
                </h2>
                <p class="disc">
                  {{ hero_card_2_description }}
                </p>
              </div>
            </div>
            <div class="col-md-4 item">
             <span v-html="hero_card_3_photo"></span>
              <div>
                <h2 class="title">
                  {{ hero_card_3_title }}
                </h2>
                <p class="disc">
                  {{ hero_card_3_description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHovered :false,
      image:'',
      main_title:'',
      secoundary_title:'',
      hero_description:'',
      hero_card_1_photo:'',
      hero_card_1_title:'',
      hero_card_1_description:'',
      hero_card_2_photo:'',
      hero_card_2_title:'',
      hero_card_2_description:'',
      hero_card_3_photo:'',
      hero_card_3_title:'',
      hero_card_3_description:'',

    };
  },
  methods:{
    handleMouseEnter() {
      this.isHovered = true;
    },
    handleMouseLeave() {
      this.isHovered = false;
    },
    handleStaticHero(StaticContent){
    StaticContent.forEach((item) => {
    if (item.key == 'hero_main_photo') {
      this.image = item.value;
    }
    if (item.key == 'main_title') {
      this.main_title = item.value;
    }
    if (item.key == 'secoundary_title') {
      this.secoundary_title = item.value;
    }
    if (item.key == 'hero_description') {
      this.hero_description = item.value;
    }
    if (item.key == 'hero_card_1_photo') {
      this.hero_card_1_photo = item.value;
    }
    if (item.key == 'hero_card_1_title') {
      this.hero_card_1_title = item.value;
    }
    if (item.key == 'hero_card_1_description') {
      this.hero_card_1_description = item.value;
    }
    if (item.key == 'hero_card_2_photo') {
      this.hero_card_2_photo = item.value;
    }
    if (item.key == 'hero_card_2_title') {
      this.hero_card_2_title = item.value;
    }
    if (item.key == 'hero_card_2_description') {
      this.hero_card_2_description = item.value;
    }
    if (item.key == 'hero_card_3_photo') {
      this.hero_card_3_photo = item.value;
    }
    if (item.key == 'hero_card_3_title') {
      this.hero_card_3_title = item.value;
    }
    if (item.key == 'hero_card_3_description') {
      this.hero_card_3_description = item.value;
    }
  });
    }
  },
  mounted(){
    this.$store.dispatch('GetStaticHeroSection').then(r => {
          this.handleStaticHero(this.$store.state.StaticHeroSection);
    });
  }
}
</script>