<template>
    <div class="order-details">
        <div class="row">
            <div class="col-md-8 ">
                <div class="date">
                    <span>
                        {{ $t('OrderDate') }}
                        <strong>{{ formatDate(OrderData.created_at) }}</strong>
                    </span>
                    <span v-if="OrderData.updated_at">
                        {{ $t('Date_of_last_update') }}
                        <strong>{{ formatDate(OrderData.updated_at) }}</strong>
                    </span>
                </div>
                <div class="stronge-title">
                    <h4>
                        {{ this.$i18n.locale == 'ar' ?
                            `الرجاء الموافقة على عرض السعر لاتمام طلبك`
                            :
                            `Please accept the quote to complete your order`
                        }}
                    </h4>
                </div>
                <div>
                    <ul class="nav nav-pills nav-pills-order-details" id="pills-tab4" role="tablist">
                        <li class="nav-item active" role="presentation">
                            <button data-index="P-D-1" class="nav-link py-3 active" id="v-pills-P-D-1-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-1" type="button" role="tab"
                                aria-controls="v-pills-P-D-1" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Service_details') }}</span>
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button data-index="P-D-2" class="nav-link py-3" id="v-pills-P-D-2-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-2" type="button" role="tab"
                                aria-controls="v-pills-P-D-2" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Service_requester') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-3" class="nav-link py-3 " id="v-pills-P-D-3-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-3" type="button" role="tab"
                                aria-controls="v-pills-P-D-3" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Server_Provider') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-4" class="nav-link py-3 " id="v-pills-P-D-4-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-4" type="button" role="tab"
                                aria-controls="v-pills-P-D-4" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Documents') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-5" class="nav-link py-3 " id="v-pills-P-D-5-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-5" type="button" role="tab"
                                aria-controls="v-pills-P-D-5" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Correspondence') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-6" class="nav-link py-3 " id="v-pills-P-D-6-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-6" type="button" role="tab"
                                aria-controls="v-pills-P-D-6" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Register_order') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-7" class="nav-link py-3 " id="v-pills-P-D-7-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-7" type="button" role="tab"
                                aria-controls="v-pills-P-D-7" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Payment') }}</span>
                            </button>
                        </li>
                        <li class="nav-item " role="presentation">
                            <button data-index="P-D-8" class="nav-link py-3 " id="v-pills-P-D-8-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-P-D-8" type="button" role="tab"
                                aria-controls="v-pills-P-D-8" aria-selected="true">
                                <span class=" fs-sm-4">{{ $t('Invoices') }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content p-d mt-3" id="pills-tabContent">
                    <div class="tab-pane fade show active p-d-1" id="v-pills-P-D-1" role="tabpanel"
                        aria-labelledby="v-pills-P-D-1-tab">
                        <PD1 
                        :OrderInfo="OrderData.service"
                        :attribute_values="OrderData.attribute_values"
                         />
                    </div>
                    <div class="tab-pane fade show p-d-2" id="v-pills-P-D-2" role="tabpanel"
                        aria-labelledby="v-pills-P-D-2-tab">
                        <PD2 :company="OrderData.company" />
                    </div>
                    <div class="tab-pane fade show p-d-3" id="v-pills-P-D-3" role="tabpanel"
                        aria-labelledby="v-pills-P-D-3-tab">
                        <PD3 
                        :service_provider="OrderData.service_provider"
                        :branch="OrderData.branch"
                         />
                    </div>
                    <div class="tab-pane fade show p-d-4" id="v-pills-P-D-4" role="tabpanel"
                        aria-labelledby="v-pills-P-D-4-tab">
                        <PD4 />
                    </div>
                    <div class="tab-pane fade show p-d-5" id="v-pills-P-D-5" role="tabpanel"
                        aria-labelledby="v-pills-P-D-5-tab">
                        <PD5 :chat_id="chat_id" />
                    </div>
                    <div class="tab-pane fade show p-d-6" id="v-pills-P-D-6" role="tabpanel"
                        aria-labelledby="v-pills--P-D-6-tab">
                        <PD6 />
                    </div>
                    <div class="tab-pane fade show p-d-7" id="v-pills-P-D-7" role="tabpanel"
                        aria-labelledby="v-pills-P-D-7-tab">
                        <PD7 />
                    </div>
                    <div class="tab-pane fade show p-d-8" id="v-pills-P-D-8" role="tabpanel"
                        aria-labelledby="v-pills-P-D-8-tab">
                        <PD8 />
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="package-order-info" :class="`package-order-info${$route.params.id}`">
                    <!-- <div class="package-order-info"> -->
                    <h2 class="title">
                        {{ this.$i18n.locale == 'ar' ? 'حالة الطلب' : 'Order Status' }}
                    </h2>
                    <ul class="order-status">
                        <li class="active">
                            <span>1</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'بانتظار الموافقة' : 'Waiting for approval' }}</div>
                        </li>
                        <li class="active">
                            <span>2</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'جاري دراسة الحالة' : 'Case study underway' }}</div>
                        </li>
                        <li>
                            <span>3</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'الموافقة على السعر' : 'Agree on the price' }}</div>
                        </li>
                        <li>
                            <span>4</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'الدفع و الايصال' : 'Payment and receipt' }}</div>
                        </li>
                        <li>
                            <span>5</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'التأكد من الدفع' : 'Verify payment' }}</div>
                        </li>
                        <li>
                            <span>6</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'العمل على الخدمات' : 'Work on services' }}</div>
                        </li>
                        <li>
                            <span>7</span>
                            <div>{{ this.$i18n.locale == 'ar' ? 'تسليم الخدمات' : 'Delivery of services' }}</div>
                        </li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
        return {
            index: 1,
            OrderData:{}
        };
    },
    components: {
        PD1: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd1.vue')),
        PD2: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd2.vue')),
        PD3: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd3.vue')),
        PD4: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd4.vue')),
        PD5: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd5.vue')),
        PD6: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd6.vue')),
        PD7: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd7.vue')),
        PD8: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Services/Order/Tabs/Pd8.vue')),
    },
    mounted() {
        let OrderId = this.$route.params.id;
        this.$store.dispatch('ServiceOrderDetails', OrderId).then(r => {
            this.OrderData = this.$store.state.ServiceOrderDetails;
            console.log('OrderData:',this.OrderData)
        });
    },
    methods:{
        formatDate(dateString){
            const date = new Date(dateString);
            const year = date.getFullYear(); // Full year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

            return `${year}/${month}/${day}`;
        }
    }
}
</script>