<template>
  <div class="service-steps" id="service-offset">
   <div class="container">
    <div class="row ">
      <div class="col-md-6 pad-content">

        <swiper class="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :space-between="20" :autoplay="{
          delay: 3000,
        }" :navigation="{
          enabled: true,
          nextEl: '.myNext-2',
          prevEl: '.myPrev-2',
        }" :pagination="{ type: 'progressbar', }" :speed="1000">
          <div class="index-steps">
            <div :class="[index > 0 ? 'active' : '']" class="step">1</div>
            <div :class="[index > 1 ? 'active' : '']" class="step">2</div>
            <div :class="[index > 2 ? 'active' : '']" class="step">3</div>
            <div :class="[index > 3 ? 'active' : '']" class="step">4</div>
            <div :class="[index > 4 ? 'active' : '']" class="step">5</div>
          </div>
          <swiper-slide v-for="item in 5" :key="item">
            <div class="item">
              <h3>
                {{ this.$i18n.locale == 'ar' ?
          `خطوات طلب الخدمة`
          :
          `Steps to request the service`
                }}
              </h3>
              <h2>
                {{ this.$i18n.locale == 'ar' ?
          `تصفح خدماتنا المتنوعة واختر ما تحتاج`
          :
          `Browse our various services and choose what you need`
                }}
              </h2>
              <p>
                هذا النص هو مثال لنص يوضع في نفس المكان يوضح طول النص الذي يمكن وضعه في المساحة المخصصة و الذي يعرض
                محتوى معين عن شيء ما على شكل اسطر مكونة من عدة اسطر لوضعها في نفس المكان
              </p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="d-flex justify-content-start navigation-button ">
          <span class="myPrev myPrev-2">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="36" rx="5" fill="#0A3041" />
              <path
                d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                fill="white" />
            </svg>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
                stroke="#AFAFAF" />
              <path
                d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                fill="#AFAFAF" />
            </svg>
          </span>
          <span class="myNext myNext-2">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="36" rx="5" fill="#0A3041" />
              <path
                d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
                fill="white" />
            </svg>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
                stroke="#AFAFAF" />
              <path
                d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
                fill="#AFAFAF" />
            </svg>
          </span>
        </div>
      </div>
      <div class="col-md-6">
        <img class="w-100" src="@/assets/img/home-page/service-steps.png" alt="">
      </div>
    </div>
   </div>
  </div>

</template>
<script>
// Import Swiper Vue.js components
export default {
  data() {
    return {
      index: 1,
      swiper: null,
      targetPath:false
    }
  },
  components: {

  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiper.autoplay.stop();
    },
    onSlideChange() {
      this.index = this.swiper.activeIndex + 1;
    },
  },
  mounted() {
    const self = this;
    if(self.targetPath ){
      window.addEventListener('scroll', function () {
      const target = document.getElementById("service-offset");
      let offsetTop = target.offsetTop;
      if(window.scrollY > (offsetTop - 300)){
        self.swiper.autoplay.start();
      }
    });
    }
  },
  watch:{
    $route (to, from){
      if(to.fullPath == '/'){
       this.targetPath = true;
      }
      else{
       this.targetPath = false;
      }
    }
} ,
};
</script>