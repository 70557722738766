<template>
   <div class="title-section" :style="style" data-aos="fade-down">
    <h1>{{ title }}</h1>
    <p>{{ secTitle }}</p>
   </div>
 </template>
 <script>
 export default {
    props: {
    title: String,
    secTitle: String,
    TextAlign: String,

  },
  data() {
    return {
        style: {
        '--text-align': this.TextAlign,
      },
    };
  }
 
 };
 </script>
 