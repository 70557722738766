<template>
       <div class="contact-services">
      <div class="row">
        <div class="col-md-4 mb-3">
          <h3>
            {{ $t("Don't hesitate") }}
          </h3>
          <h1>{{ $t('To contact us !') }}</h1>
          <p>
            {{ $t('contat-desc') }}
          </p>
        </div>
        <div class="col-md-8">
          <Form class="form-contact" @submit="handelForm" :validation-schema="schema">
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="name" type="text" :placeholder="$t('FullName')" />
                <ErrorMessage name="name" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="company" type="text" :placeholder="$t('CompanyName')" />
                <ErrorMessage name="company" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="phone" type="text" :placeholder="$t('PhoneNumber')" />
                <ErrorMessage name="phone" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field name="ReasonCommunication" v-slot="{field}">
                  <multiselect v-model="value" v-bind="field" :options=" Reasons " @select="PickId "
                  :placeholder=" this.$i18n.locale == 'ar' ? 'اختر سبب التواصل' : 'Choose the reason' " label="text" track-by="text" selectLabel="" 
                  :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
                  :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'"></multiselect>
                </Field>
                <ErrorMessage name="ReasonCommunication" class="error-message" />
              </div>
            </div>
            <div class="col-12 mb-2">
              <Field class="contact-field" as="textarea" name="messege" :placeholder=" $t('messege') " />
              <ErrorMessage name="messege" class="error-message" />
              <div class="d-flex justify-content-end mt-3">
                <button class="btn-main" type="submit">{{ $t('Request_a_consultation') }}</button>
              </div>
            </div>
          </div>
        </Form>
        </div>
      </div>
     </div>
</template>
<script>
// import axios from 'axios';
// import { notify } from "@kyvg/vue3-notification";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Multiselect from 'vue-multiselect';
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      name: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'Name required').min(6),
      phone: yup.object().required(cookie.get('lang') == 'ar' ? 'رقم الموبايل مطلوب' : 'Mobile required'),
      ReasonCommunication: yup.object().required(cookie.get('lang') == 'ar' ? 'اختيار السبب مطلوب' : 'reason required'),
      company: yup.string().required(cookie.get('lang') == 'ar' ? 'اسم الشركة مطلوب' : 'company name required').min(4),
      messege: yup.string().required(cookie.get('lang') == 'ar' ? 'يجب كتابة الرسالة' : 'messege required').min(10),
    });
    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      Reasons: [],
      value: '',
      reason_value:'',
    };
  },
  methods:{
    PickId () {
      this.reason_value = this.value.id;
      console.log(this.reason_value)
    },
  },
  mounted() {
    this.$store.dispatch('GetContactReasons').then(r => {
      this.Reasons = this.$store.state.ContactReasons;
    });
  }

}
</script>