<template>
  <div class="rate-card" :class="[this.$store.state.showRate ? `open` : ``]">
    <div class="overlay" @click="this.$store.state.showRate = false"></div>
    <div class="card-package">
      <div class="col-12">
        <img height="110" src="@/assets/img/packages/rate.png">
      </div>
      <div class="col-12">
        <p class=" text-center mt-4 thanks-p">{{ this.$i18n.locale == `ar` ? `شكرا لاختياركم إتمام...` : `Thank you for
          choosing to etmaam...!` }}</p>
        <h2 class="title text-center" style="font-size: 30px; margin-bottom: 30px;">
          {{ this.$i18n.locale == `ar` ? `قم بتقييم الخدمة !` : `Rate the service!` }}</h2>
      </div>
      <p style="font-size: 12px;" class="opinion">
        {{ this.$i18n.locale == `ar` ? `آراؤكم هي مرآتنا التي نعكس فيها جودتنا. قم بتقييم تجربتك معنا وساعدنا في بناء
        مجتمع من العملاء الراضين.قيمنا الآن وكن جزءًا من عائلتنا!`
          : `Your opinions are our mirror in which we reflect our quality. Rate your experience with us and help us build
        a community of satisfied customers. Rate us now and become part of our family!` }}</p>
      <div class="d-flex justify-content-center">
        <svg class="mr-2" width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.171 11.4719L18.3679 1.00789C18.5192 0.705055 18.7518 0.450346 19.0398 0.272323C19.3278 0.094299 19.6596 0 19.9982 0C20.3368 0 20.6686 0.094299 20.9566 0.272323C21.2445 0.450346 21.4772 0.705055 21.6285 1.00789L26.8254 11.4719L38.4435 13.1599C38.7786 13.2064 39.0939 13.3461 39.3534 13.5631C39.6129 13.7802 39.8061 14.0658 39.911 14.3874C40.016 14.7089 40.0283 15.0535 39.9468 15.3818C39.8652 15.7101 39.6929 16.0088 39.4497 16.2439L31.0442 24.3839L33.0285 35.8839C33.2826 37.3599 31.7223 38.4839 30.3881 37.7879L19.9982 32.3559L9.60634 37.7879C8.2741 38.4859 6.71382 37.3599 6.96786 35.8819L8.95222 24.3819L0.546715 16.2419C0.304644 16.0067 0.133437 15.7082 0.0525542 15.3805C-0.0283283 15.0528 -0.015642 14.709 0.0891709 14.3882C0.193984 14.0674 0.386719 13.7823 0.64547 13.5656C0.90422 13.3488 1.21861 13.2089 1.55289 13.1619L13.171 11.4719Z"
            fill="#F0BF14" />
        </svg>
        <svg class="mr-2" width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.171 11.4719L18.3679 1.00789C18.5192 0.705055 18.7518 0.450346 19.0398 0.272323C19.3278 0.094299 19.6596 0 19.9982 0C20.3368 0 20.6686 0.094299 20.9566 0.272323C21.2445 0.450346 21.4772 0.705055 21.6285 1.00789L26.8254 11.4719L38.4435 13.1599C38.7786 13.2064 39.0939 13.3461 39.3534 13.5631C39.6129 13.7802 39.8061 14.0658 39.911 14.3874C40.016 14.7089 40.0283 15.0535 39.9468 15.3818C39.8652 15.7101 39.6929 16.0088 39.4497 16.2439L31.0442 24.3839L33.0285 35.8839C33.2826 37.3599 31.7223 38.4839 30.3881 37.7879L19.9982 32.3559L9.60634 37.7879C8.2741 38.4859 6.71382 37.3599 6.96786 35.8819L8.95222 24.3819L0.546715 16.2419C0.304644 16.0067 0.133437 15.7082 0.0525542 15.3805C-0.0283283 15.0528 -0.015642 14.709 0.0891709 14.3882C0.193984 14.0674 0.386719 13.7823 0.64547 13.5656C0.90422 13.3488 1.21861 13.2089 1.55289 13.1619L13.171 11.4719Z"
            fill="#F0BF14" />
        </svg>

        <svg class="mr-4" width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.171 11.4719L18.3679 1.00789C18.5192 0.705055 18.7518 0.450346 19.0398 0.272323C19.3278 0.094299 19.6596 0 19.9982 0C20.3368 0 20.6686 0.094299 20.9566 0.272323C21.2445 0.450346 21.4772 0.705055 21.6285 1.00789L26.8254 11.4719L38.4435 13.1599C38.7786 13.2064 39.0939 13.3461 39.3534 13.5631C39.6129 13.7802 39.8061 14.0658 39.911 14.3874C40.016 14.7089 40.0283 15.0535 39.9468 15.3818C39.8652 15.7101 39.6929 16.0088 39.4497 16.2439L31.0442 24.3839L33.0285 35.8839C33.2826 37.3599 31.7223 38.4839 30.3881 37.7879L19.9982 32.3559L9.60634 37.7879C8.2741 38.4859 6.71382 37.3599 6.96786 35.8819L8.95222 24.3819L0.546715 16.2419C0.304644 16.0067 0.133437 15.7082 0.0525542 15.3805C-0.0283283 15.0528 -0.015642 14.709 0.0891709 14.3882C0.193984 14.0674 0.386719 13.7823 0.64547 13.5656C0.90422 13.3488 1.21861 13.2089 1.55289 13.1619L13.171 11.4719Z"
            fill="#F0BF14" />
        </svg>
        <svg class="mr-2" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.171 12.4719L19.3679 2.00789C19.5192 1.70505 19.7518 1.45035 20.0398 1.27232C20.3278 1.0943 20.6596 1 20.9982 1C21.3368 1 21.6686 1.0943 21.9566 1.27232C22.2445 1.45035 22.4772 1.70505 22.6285 2.00789L27.8254 12.4719L39.4435 14.1599C39.7786 14.2064 40.0939 14.3461 40.3534 14.5631C40.6129 14.7802 40.8061 15.0658 40.911 15.3874C41.016 15.7089 41.0283 16.0535 40.9468 16.3818C40.8652 16.7101 40.6929 17.0088 40.4497 17.2439L32.0442 25.3839L34.0285 36.8839C34.2826 38.3599 32.7223 39.4839 31.3881 38.7879L20.9982 33.3559L10.6063 38.7879C9.2741 39.4859 7.71382 38.3599 7.96786 36.8819L9.95222 25.3819L1.54671 17.2419C1.30464 17.0067 1.13344 16.7082 1.05255 16.3805C0.971672 16.0528 0.984358 15.709 1.08917 15.3882C1.19398 15.0674 1.38672 14.7823 1.64547 14.5656C1.90422 14.3488 2.21861 14.2089 2.55289 14.1619L14.171 12.4719Z"
            fill="white" stroke="#AFBFC6" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg class="mr-2" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.171 12.4719L19.3679 2.00789C19.5192 1.70505 19.7518 1.45035 20.0398 1.27232C20.3278 1.0943 20.6596 1 20.9982 1C21.3368 1 21.6686 1.0943 21.9566 1.27232C22.2445 1.45035 22.4772 1.70505 22.6285 2.00789L27.8254 12.4719L39.4435 14.1599C39.7786 14.2064 40.0939 14.3461 40.3534 14.5631C40.6129 14.7802 40.8061 15.0658 40.911 15.3874C41.016 15.7089 41.0283 16.0535 40.9468 16.3818C40.8652 16.7101 40.6929 17.0088 40.4497 17.2439L32.0442 25.3839L34.0285 36.8839C34.2826 38.3599 32.7223 39.4839 31.3881 38.7879L20.9982 33.3559L10.6063 38.7879C9.2741 39.4859 7.71382 38.3599 7.96786 36.8819L9.95222 25.3819L1.54671 17.2419C1.30464 17.0067 1.13344 16.7082 1.05255 16.3805C0.971672 16.0528 0.984358 15.709 1.08917 15.3882C1.19398 15.0674 1.38672 14.7823 1.64547 14.5656C1.90422 14.3488 2.21861 14.2089 2.55289 14.1619L14.171 12.4719Z"
            fill="white" stroke="#AFBFC6" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


      </div>
      <div>
        <h5 class="mt-4">{{ this.$i18n.locale == `ar` ? `اوصف لنا تجربتك مع الخدمة و اترك لنا ملاحظاتك !` : `Describe
          your experience with the service and leave us your comments!` }}</h5>
        <textarea type="text" name="massage" :placeholder="$t('Notes')"  cols="5" rows="3"></textarea>


      </div>
      <div class="mt-4 d-flex justify-content-center sub-title">
        <router-link style="margin-right: 15px;" to="/register" class="next-step Submit">
          {{ this.$i18n.locale == `ar` ? `تخطي` : `skip` }}
        </router-link>

        <router-link style="margin-right: 15px;" class="next-step " to="/login">
          {{ this.$i18n.locale == `ar` ? `إرسال التقييم` : `Submit evaluation` }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Blogs Details',
  data() { },
  methods: {
    CloseModal() {
      this.$store.state.showRate = false
    }
  }
}


</script>
