<template>
   <div class="m-p">
      <Documents />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Commercial',
components: {
   Documents: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/Documents/Documents.vue') ),
},
}
</script>