<template>
    <div id="join-us">
    <HeadSections class="join-img"
    :TitlePage="$route.name"
    :TitleSec="this.$i18n.locale == 'ar' ?`لتسهيل مشروعك و اتمام عملك على اتم وجه`:`To facilitate your project and complete your work perfectly.`"
    :PathPageFrom="$t('Home')"
    :PathPageTo="$t('JoinUs')" />
    <div class="container"  style=" overflow: hidden;margin-top: -100px;">
    <IndexJoinUs />
      
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'JoinUs Page',
  components: {
    HeadSections: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Global/HeadSections.vue') ),
    IndexJoinUs: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/JoinUs/IndexJoinUs.vue') ),
  },
  data() {
    return {
      titleAr: 'عن منصة إتمام !',
      titleEn: 'About Etmaam Company !',
    };
  },
  }
  </script>

  
  

  