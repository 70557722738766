<template>
  <div class="testimonials">
    <TitleSection :title="$t('TestimonialsTitle')" :secTitle="$t('TextExample')" :TextAlign="`center`" />

    <!-- Custom navigation buttons -->
    <div class="container">
      <div class="row">
      <div class="col-12 d-flex navigation-button ">
        <span class="myPrev">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="5" fill="#0A3041" />
            <path
              d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
              fill="white" />
          </svg>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
              stroke="#AFAFAF" />
            <path
              d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
              fill="#AFAFAF" />
          </svg>
        </span>
        <span class="myNext">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="5" fill="#0A3041" />
            <path
              d="M13.6796 17.2929L17.9293 12.9999L16.9395 12L11 18L11.4949 18.4999L16.9395 24L17.9293 23.0001L13.6796 18.7071H25V17.2929H13.6796Z"
              fill="white" />
          </svg>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="35.5" y="35.5" width="35" height="35" rx="4.5" transform="rotate(180 35.5 35.5)" fill="white"
              stroke="#AFAFAF" />
            <path
              d="M22.3204 18.7071L18.0707 23.0001L19.0605 24L25 18L24.5051 17.5001L19.0605 12L18.0707 12.9999L22.3204 17.2929L11 17.2929L11 18.7071L22.3204 18.7071Z"
              fill="#AFAFAF" />
          </svg>
        </span>
      </div>
    </div>

    <swiper class="swiper"
     :space-between="20"
     :navigation="{
      enabled: true,
      nextEl: '.myNext',
      prevEl: '.myPrev',
    }" :autoplay="{
      delay: 3000,
    }"
    :speed="1000"
    :breakpoints="swiperOptions.breakpoints">
      <swiper-slide class="item" v-for="(item, index) in Testimonials" :key="index">
        <img :src="item.image" alt="">
        <div>
          <h2>{{ item.name }}</h2>
          <p>{{ item.text.slice(0,50) }}..</p>
        </div>
        <div class="info">
          {{ item.text }}
        </div>
      </swiper-slide>
    </swiper>
    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  data() {
    return {
      dirlang:'',
      swiperOptions: {
        breakpoints: {
          300: { slidesPerView: 1, spaceBetween: 50 },
          576: { slidesPerView: 2, spaceBetween: 50 },
          767: { slidesPerView: 2, spaceBetween: 50 },
          991: { slidesPerView: 3, spaceBetween: 50 },
          1200: { slidesPerView: 3, spaceBetween: 50 },
        }
      }
    }
  },
  components: {
  TitleSection: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Global/TitleSection.vue')),
  },
  computed:{
    Testimonials(){
            return this.$store.state.Testimonials
        }
    },
    methods: {
      onImageLoadFailure (event) {
        event.target.src = '@/assets/img/global/Logo1.png'
      }
    },
    mounted(){
        this.$store.dispatch('GetTestimonials');
      }

};
</script>
