<template>
  <div class="hero hero-order">
    <div class="container">
      <p style="font-size: 14px;">
        <a :href=pathFrom style="color: inherit; text-decoration: none;">
          {{ PathPageFrom }}
        </a>
        &nbsp;
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.85742 10.3641L3.44858 6L7.85742 1.63594L6.2047 -7.22428e-08L0.143136 6L6.2047 12L7.85742 10.3641Z" fill="#AFAFAF" />
        </svg>
        &nbsp;
        <a v-if="PathPageCenter" :href=pathCenter style="color: inherit; text-decoration: none;">
          {{ PathPageCenter }}
        </a>
        <svg v-show="PathPageCenter" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.85742 10.3641L3.44858 6L7.85742 1.63594L6.2047 -7.22428e-08L0.143136 6L6.2047 12L7.85742 10.3641Z" fill="#AFAFAF" />
        </svg>
        &nbsp;
        <a :href="pathTo" style="color: #225476; text-decoration: none;">
          {{ PathPageTo }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    PathPageFrom: String,
    PathPageCenter: String,
    PathPageTo: String,
    pathCenter: String,
    pathFrom:String
  },

};
</script>
