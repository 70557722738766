<template>
    <div class="required-item">
        <div class="container">
            <h2 class="title-profile mt-0">{{ $route.name }}</h2>
            <div class="row">
               <div class="head-section-profile">
                <div>
                    <select name="" id="">
                        <option value="">{{ this.$i18n.locale == 'ar' ? 'التصنيف حسب...':'Filter By...' }}</option>
                    </select>
                </div>
                <div>
                    <button class="btn-main">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z" fill="white"/>
                        </svg>
                        {{ $t('Request_a_service') }}
                    </button>
                </div>
               </div>
               <div class="col-md-4" v-for="item in 6" :key="item">
                            <div class="card-ser-req" style="box-shadow: 0px 5px 30px 0px rgba(10, 48, 65, 0.10); ">
                                <h2 class="name">هذا النص هو مثال لاسم الخدمة </h2>
                                <h3 class="category">هذا النص مثال لاسم القسم</h3>
                                <div class="data-list">
                                    <div class="item">
                                        <div class="key">{{ $t('order_number')}}</div>
                                        <div class="value">421</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('Date_created')}}</div>
                                        <div class="value">22 فبراير 2022</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('Service_start_date')}}</div>
                                        <div class="value">25 فبراير 2022</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('The_office')}}</div>
                                        <div class="value">مثال لاسم المكتب</div>
                                    </div>
                                </div>
                                <div class="bottom-card">
                                    <div class="status review">{{ $t('In_process') }}</div>
                                    <router-link to="">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.31779 11.6027L0.373973 6.53275C0.299066 6.45593 0.245883 6.37271 0.214422 6.28309C0.182962 6.19347 0.167481 6.09745 0.16798 5.99502C0.16798 5.8926 0.183711 5.79658 0.215172 5.70696C0.246632 5.61733 0.299566 5.53412 0.373973 5.4573L5.31779 0.387326C5.45512 0.246494 5.6269 0.173005 5.83314 0.16686C6.03938 0.160714 6.21716 0.234203 6.36647 0.387326C6.51629 0.528159 6.59444 0.704328 6.60093 0.915833C6.60742 1.12734 6.53551 1.30965 6.3852 1.46277L2.71479 5.22684H11.0856C11.2978 5.22684 11.4758 5.30059 11.6197 5.44808C11.7635 5.59557 11.8351 5.77788 11.8346 5.99502C11.8346 6.21267 11.763 6.39524 11.6197 6.54273C11.4763 6.69022 11.2983 6.76371 11.0856 6.7632H2.71479L6.3852 10.5273C6.52253 10.6681 6.59444 10.8473 6.60093 11.065C6.60742 11.2826 6.53551 11.4619 6.3852 11.6027C6.24787 11.7564 6.07309 11.8332 5.86086 11.8332C5.64862 11.8332 5.4676 11.7564 5.31779 11.6027Z" fill="#225476"/>
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                </div>
                <div class="col-12 mt-5">
                    <div class="pagination">
                        <svg @click="prevPage" :class="[index == 1 ? 'disabled':'']"
                        class="prev " width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.404541 6.87983C0.145501 6.64436 0 6.32515 0 5.99234C0 5.65953 0.145501 5.34033 0.404541 5.10485L5.62038 0.367661C5.87988 0.132173 6.23178 -7.8473e-05 6.59868 3.49332e-08C6.78034 3.89081e-05 6.96023 0.0325644 7.12805 0.0957197C7.29587 0.158875 7.44835 0.251423 7.57678 0.36808C7.70521 0.484737 7.80707 0.623218 7.87656 0.775616C7.94604 0.928015 7.98178 1.09135 7.98174 1.25629C7.98169 1.42122 7.94587 1.58454 7.87631 1.73691C7.80674 1.88928 7.70481 2.02772 7.57632 2.14432L3.33891 5.99234L7.57724 9.84037C7.70942 9.95617 7.81487 10.0947 7.88744 10.2479C7.96002 10.4011 7.99826 10.5659 7.99994 10.7326C8.00162 10.8994 7.96671 11.0648 7.89724 11.2191C7.82777 11.3735 7.72513 11.5138 7.59531 11.6317C7.4655 11.7497 7.3111 11.843 7.14113 11.9063C6.97117 11.9695 6.78904 12.0013 6.60537 12C6.4217 11.9986 6.24017 11.964 6.07137 11.8983C5.90258 11.8325 5.74989 11.7369 5.62222 11.617L0.402697 6.87983H0.404541Z" fill="white"/>
                        </svg> 
                        <span class="current">
                            {{index}}
                        </span>
                        <span style="margin: 0 5px;">/</span>
                        <span class="pageLength">{{ pageLength }}</span>
                        <svg @click="nextPage" :class="[index == pageLength ? 'disabled':'']"
                        class="next" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59546 5.12017C7.8545 5.35564 8 5.67485 8 6.00766C8 6.34047 7.8545 6.65967 7.59546 6.89515L2.37962 11.6323C2.12012 11.8678 1.76822 12.0001 1.40132 12C1.21966 12 1.03977 11.9674 0.87195 11.9043C0.704126 11.8411 0.551648 11.7486 0.423219 11.6319C0.29479 11.5153 0.192926 11.3768 0.123444 11.2244C0.0539621 11.072 0.0182218 10.9087 0.0182647 10.7437C0.0183076 10.5788 0.0541328 10.4155 0.123694 10.2631C0.193255 10.1107 0.29519 9.97229 0.42368 9.85568L4.66109 6.00766L0.422756 2.15963C0.290581 2.04384 0.18513 1.9053 0.112556 1.75211C0.0399819 1.59892 0.00173953 1.43414 5.72359e-05 1.26738C-0.00162506 1.10063 0.0332885 0.935241 0.10276 0.780869C0.172232 0.626497 0.27487 0.486232 0.404687 0.368259C0.534504 0.250286 0.688899 0.156967 0.858864 0.0937468C1.02883 0.0305268 1.21096 -0.00132692 1.39463 4.34928e-05C1.5783 0.00141295 1.75983 0.0359789 1.92863 0.101725C2.09742 0.167471 2.25011 0.263081 2.37777 0.382974L7.5973 5.12017L7.59546 5.12017Z" fill="#909090"/>
                        </svg> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index:1,
            pageLength:5
        }
    },
    methods:{
        prevPage(){
            if(this.index != 1){
                this.index--
            }
        },
        nextPage(){
            if(this.index != this.pageLength){
                this.index++
            }
        }
      
    }
};
</script>