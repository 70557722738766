<template>
  <div class="login-card">
    <div class="row">
      <div class="col-md-6 d-md-block d-none">
        <img class="w-100" src="../../assets/img/auth/login-backgroun.png" alt="">
        <div class="etmaam">
          <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
          <p>
            {{
              this.$i18n.locale == 'ar' ?
                `استفد من خبرتنا واستمتع بخدمات متميزة تلبي احتياجاتك بدقة واحترافية.`
                :
                `Benefit from our experience and enjoy distinguished services that meet your needs accurately and
            professionally.`

            }}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <Form class="auth-form" @submit="HandelLogin" :validation-schema="schema">
          <div class="row">
            <div class="col-12">
              <h1 class="title">{{ $t('Login') }}</h1>
            </div>
            <div class="col-12 field">
              <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.85077 17.8343C2.25742 17.8441 1.68096 17.6366 1.23003 17.2508C1.03033 17.0756 0.870029 16.8601 0.759682 16.6185C0.649334 16.3768 0.59145 16.1145 0.589844 15.8489V2.1537C0.59347 1.88682 0.653881 1.62377 0.767058 1.38204C0.880235 1.14032 1.04358 0.925463 1.24624 0.751767C1.69717 0.365988 2.27363 0.158463 2.86697 0.168303H11.1327C11.7261 0.158463 12.3025 0.365988 12.7534 0.751767C12.9561 0.925463 13.1195 1.14032 13.2326 1.38204C13.3458 1.62377 13.4062 1.88682 13.4098 2.1537V15.8489C13.4042 16.1154 13.3429 16.3778 13.2298 16.6192C13.1168 16.8607 12.9545 17.0758 12.7534 17.2508C12.3025 17.6366 11.7261 17.8441 11.1327 17.8343H2.85077ZM2.85077 1.27851C2.60485 1.26522 2.36367 1.34982 2.17994 1.51381C1.9962 1.67781 1.88486 1.90786 1.87022 2.1537V15.8489C1.88486 16.0947 1.9962 16.3248 2.17994 16.4888C2.36367 16.6528 2.60485 16.7374 2.85077 16.7241H11.1327C11.3786 16.7374 11.6198 16.6528 11.8035 16.4888C11.9873 16.3248 12.0986 16.0947 12.1133 15.8489V2.1537C12.0986 1.90786 11.9873 1.67781 11.8035 1.51381C11.6198 1.34982 11.3786 1.26522 11.1327 1.27851H2.85077ZM5.54929 15.2897C5.38253 15.2891 5.22152 15.2288 5.09548 15.1196C5.03184 15.0729 4.98008 15.0119 4.94441 14.9415C4.90873 14.8711 4.89014 14.7933 4.89014 14.7144C4.89014 14.6355 4.90873 14.5577 4.94441 14.4873C4.98008 14.4169 5.03184 14.3559 5.09548 14.3092C5.2223 14.2023 5.38344 14.1448 5.54929 14.1471H8.43419C8.60004 14.1448 8.76118 14.2023 8.888 14.3092C8.95164 14.3559 9.0034 14.4169 9.03907 14.4873C9.07475 14.5577 9.09334 14.6355 9.09334 14.7144C9.09334 14.7933 9.07475 14.8711 9.03907 14.9415C9.0034 15.0119 8.95164 15.0729 8.888 15.1196C8.76196 15.2288 8.60095 15.2891 8.43419 15.2897H5.54929Z"
                  fill="#0A3041" />
              </svg>
              <!-- <input v-model="formData.phone" @input="handelPhoneError"
        required
        type="phone" 
        name="phone" 
        id="phone" 
        :placeholder="$t('PhoneNumber')"> -->
              <Field name="email" type="email" :placeholder="$t('Email')" />
              <ErrorMessage name="email" class="error-message" />
            </div>
            <div class="col-12 field" style="position: relative">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0A3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0A3041" />
              </svg>
              <Field name="password" :type="type_password" id="password" :placeholder="$t('Password')" />
              <ErrorMessage name="password" class="error-message" />
              <svg @click="showPassword" style="position: absolute;right:auto;
                    left: 26px;
                    top: 16px;
                    cursor: pointer;" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
            </div>
            <div class="col-12 mt-1">
              <router-link to="/forgot" class="forgot">
                {{ $t('Forgot_Password') }}
              </router-link>
            </div>
            <div class="col-12">
              <button class="btn-main">{{ $t('Login') }}</button>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-between ">
              <span>
                {{ this.$i18n.locale == 'ar' ? `ليس لديك حساب بعد ؟` : `Don't have an account yet ?` }}
              </span>
              <router-link to="/register" class="btn-third">
                {{ $t('Register') }}
              </router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
// import vee-validate 
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      type_password: 'password',
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الالكتروني مطلوب':'email is required').email(cookie.get('lang') == 'ar' ? 'يجب ان يكون البريد الالكتروني محقق':'email must be valid'),
      password: yup.string().required(cookie.get('lang') == 'ar' ? 'كلمة المرور  مطلوبة':'password is required').min(8,cookie.get('lang') == 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل':'password must be at least 8 characters'),
    });

    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    showPassword() {
      if (this.type_password == 'text') {
        this.type_password = 'password'
      }
      else {
        this.type_password = 'text';
      }
    },
    async HandelLogin(values) {
      const formData = {
        "email": values.email,
        "password": values.password,

      }
      await axios.post(`/user/auth/login`, formData)
        .then((res) => {
          this.$router.push('/profile/my-profile');
          notify({
              type: "success",
              title: "تسجيل الدخول",
              text: this.$i18n.locale == 'ar' ? "تم تسجيل الدخول بنجاح":'LogIn Success',
            });
            this.$store.commit("SET_Token", res.data.data);
        })
        .catch(function (error) {
          if(error){
            notify({
              type: "error",
              title: "خـطـأ !!",
              text: error?.response?.data?.message,
            });
          }
        });
    }
  }
}
</script>