<template>
        <div class="d-flex justify-content-between writer">
                        <strong style="line-height: 35px;">{{ $t('with') }} {{ BlogsDetails.author }} </strong>
                        <div class="d-flex flex-wrap">
                            <div class="small-blocks">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M7.5 4.3501C7.5 3.79781 7.05228 3.3501 6.5 3.3501C5.94772 3.3501 5.5 3.79781 5.5 4.3501V5.75007C4.37742 5.75242 3.78807 5.7822 3.4102 6.16007C3 6.57027 3 7.22967 3 8.54987C3 8.87887 3 9.04477 3.1022 9.14767C3.2051 9.24987 3.3703 9.24987 3.7 9.24987H16.3C16.629 9.24987 16.7949 9.24987 16.8978 9.14767C17 9.04477 17 8.87957 17 8.54987C17 7.22967 17 6.57027 16.5898 6.16007C16.2119 5.7822 15.6226 5.75242 14.5 5.75007V4.3501C14.5 3.79781 14.0523 3.3501 13.5 3.3501C12.9477 3.3501 12.5 3.79781 12.5 4.3501V5.74987H7.5V4.3501ZM3.4102 17.2397C3 16.8295 3 16.1701 3 14.8499V11.3499C3 11.0209 3 10.855 3.1022 10.7521C3.2051 10.6499 3.3703 10.6499 3.7 10.6499H16.3C16.629 10.6499 16.7949 10.6499 16.8978 10.7521C17 10.855 17 11.0202 17 11.3499V14.8499C17 16.1701 17 16.8295 16.5898 17.2397C16.1796 17.6499 15.5202 17.6499 14.2 17.6499H5.8C4.4798 17.6499 3.8204 17.6499 3.4102 17.2397Z"
                                        fill="#225476" />
                                </svg>

                               <span>{{ BlogsDetails.published_at }}</span>
                            </div>
                            <div class="small-blocks mr-2">
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.267 6.50011C11.267 7.75199 10.2521 8.76684 9.00024 8.76684C7.74836 8.76684 6.73351 7.75199 6.73351 6.50011C6.73351 5.24823 7.74836 4.23338 9.00024 4.23338C10.2521 4.23338 11.267 5.24823 11.267 6.50011Z"
                                        fill="#225476" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M14.138 2.01883C15.6426 3.09243 16.7997 4.58339 17.4662 6.30744C17.5113 6.43194 17.5113 6.56828 17.4662 6.69278C16.7997 8.41683 15.6426 9.90779 14.138 10.9814C12.6333 12.055 10.8471 12.6642 9 12.7336C7.15291 12.6642 5.36665 12.055 3.862 10.9814C2.35735 9.90779 1.20027 8.41683 0.53376 6.69278C0.488747 6.56828 0.488747 6.43194 0.53376 6.30744C1.20027 4.58339 2.35735 3.09243 3.862 2.01883C5.36665 0.945236 7.15291 0.336062 9 0.266602C10.8471 0.336062 12.6333 0.945236 14.138 2.01883ZM6.95359 9.56278C7.55933 9.96752 8.27148 10.1835 9 10.1835C9.97645 10.182 10.9125 9.79349 11.6029 9.10304C12.2934 8.41259 12.6819 7.47656 12.6834 6.50011C12.6834 5.7716 12.4674 5.05944 12.0627 4.4537C11.6579 3.84797 11.0826 3.37585 10.4096 3.09706C9.73653 2.81827 8.99591 2.74532 8.2814 2.88745C7.56688 3.02958 6.91055 3.38039 6.39542 3.89553C5.88028 4.41067 5.52946 5.06699 5.38734 5.78151C5.24521 6.49602 5.31816 7.23664 5.59695 7.9097C5.87574 8.58276 6.34785 9.15804 6.95359 9.56278Z"
                                        fill="#225476" />
                                </svg>
                               <span> {{ BlogsDetails.views }} {{ $t('views') }}</span>

                            </div>
                            <div class="small-blocks mr-2">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2.5 8.92171C2.5 7.05505 2.5 6.12171 2.86333 5.40838C3.18291 4.7812 3.69282 4.27129 4.32 3.95171C5.03333 3.58838 5.96667 3.58838 7.83333 3.58838H12.1667C14.0333 3.58838 14.9667 3.58838 15.68 3.95171C16.3072 4.27129 16.8171 4.7812 17.1367 5.40838C17.5 6.12171 17.5 7.05505 17.5 8.92171V9.92171C17.5 11.7884 17.5 12.7217 17.1367 13.435C16.8171 14.0622 16.3072 14.5721 15.68 14.8917C14.9667 15.255 14.0333 15.255 12.1667 15.255H6.17833C5.95734 15.2551 5.74541 15.3429 5.58917 15.4992L3.9225 17.1659C3.3975 17.6909 2.5 17.3192 2.5 16.5767V11.0884V8.92171ZM7.5 6.92171C7.27899 6.92171 7.06702 7.00951 6.91074 7.16579C6.75446 7.32207 6.66667 7.53403 6.66667 7.75505C6.66667 7.97606 6.75446 8.18802 6.91074 8.3443C7.06702 8.50058 7.27899 8.58838 7.5 8.58838H12.5C12.721 8.58838 12.933 8.50058 13.0893 8.3443C13.2455 8.18802 13.3333 7.97606 13.3333 7.75505C13.3333 7.53403 13.2455 7.32207 13.0893 7.16579C12.933 7.00951 12.721 6.92171 12.5 6.92171H7.5ZM7.5 10.255C7.27899 10.255 7.06702 10.3428 6.91074 10.4991C6.75446 10.6554 6.66667 10.8674 6.66667 11.0884C6.66667 11.3094 6.75446 11.5214 6.91074 11.6776C7.06702 11.8339 7.27899 11.9217 7.5 11.9217H10C10.221 11.9217 10.433 11.8339 10.5893 11.6776C10.7455 11.5214 10.8333 11.3094 10.8333 11.0884C10.8333 10.8674 10.7455 10.6554 10.5893 10.4991C10.433 10.3428 10.221 10.255 10 10.255H7.5Z"
                                        fill="#225476" />
                                </svg>
                               <span>{{ BlogComments.length }} {{ $t('SumComments') }}</span>


                            </div>
                            <div style="margin-right: 12px;">

                                <button class="btn btn-sm btn-primary share-btn p-2">
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16 10.5L11.2 5.25V7.875C8.8 7.875 4 9.45 4 15.75C4 14.8748 5.44 13.125 11.2 13.125V15.75L16 10.5Z"
                                            fill="white" stroke="white" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                   <span>{{ $t('share') }}</span>


                                </button>
                            </div>
                        </div>
                    </div>
</template>

<script>
export default {
    props: {
        BlogComments: {
            type: Object,
            required: true
        },
        BlogsDetails: {
            type: Object,
            required: true
        },

    },
}

</script>
