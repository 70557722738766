<template>
    <div class="services">
        <div class="row">
            <div class="col-sm-3 guide" data-aos="fade-down">
                <h1>{{ this.$i18n.locale == 'ar' ? 'ما هي الخدمات التي نقدمها لك؟' : 'What services do we provide you?'
                    }}
                </h1>
                <p>
                    {{
                    this.$i18n.locale == 'ar' ?
                        `هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث
                    يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها
                    التطبيق.`
                        :
                        `This text is an example of text that can be replaced in the same space. This text was generated
                    from the Arabic text generator, where you can generate such text or many other texts in addition to
                    increasing the number of letters that the application generates.`
                }}
                </p>
            </div>
            <div class="col-sm-9 services-list">
                <swiper class="swiper" :space-between="30" :speed="1000" :breakpoints="swiperOptions.breakpoints"
                    :slidesPerView="3" navigation :pagination="{ type: 'progressbar', }">
                    <swiper-slide v-for="item in Services" :key="item.id" @mouseenter="handleMouseEnter"
                        @mouseleave="handleMouseLeave">
                        <div class="item">
                            <div class="image">
                                <img @error="onImageLoadFailure($event)" :src="item.image" alt="">
                            </div>
                            <h2>{{ item.title }}</h2>
                            <p>{{ item.description }}</p>
                        </div>
                    </swiper-slide>
                       
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    300: { slidesPerView: 1, spaceBetween: 50 },
                    576: { slidesPerView: 2, spaceBetween: 25 },
                    767: { slidesPerView: 2, spaceBetween: 25 },
                    991: { slidesPerView: 3, spaceBetween: 25 },
                    1200: { slidesPerView: 3, spaceBetween: 25 },
                }
            },


        }
    },
    computed: {
        Services() {
            return this.$store.state.Services_Type
        }
    },
    methods: {
        onImageLoadFailure(event) {
            event.target.src = '@/assets/img/global/Logo.png'
        },
        handleMouseEnter() {
            this.isHovered = true;
        },
        handleMouseLeave() {
            this.isHovered = false;
        }
    },
    mounted() {
        this.$store.dispatch('GetServicesType');
    }
};
</script>