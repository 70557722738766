<template>
    <div class="row ">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Register_order') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
        <div class="register-order-list">
            <div class="item" v-for="item in 5">
                <div class="d-flex align-items-center">
                    <span></span>
                    <div class="title">هذا النص مثال لعنوان يوضح خطوة بلسجل</div>
                </div>
                <div class="description">هذا النص مثال لنص يوضح شرح عن السجل و يكون عبارة عن عدة اسطر او عدة كلمات تكتب في هذا الحثل و يمتد لطول معين</div>
                <div class="date">5/5/2024</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      imageId:[],
    }
  },
  methods:{
    SetimageId(e){
      console.log(event.target.files);
      this.imageId.push(event.target.files[0])
    },
    downloadFile(file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      URL.revokeObjectURL(url);
    },
  }

};
</script>