<template>
    <div id="nav-profile">
            <SideBar @Show="show = !show" :class="[show ? 'open':'']"  />
            <div @click="show = !show" class="overlay d-block d-md-none" :class="[show ? 'open':'']"></div>
            <TopBar @Show="show = !show" />
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
      return {
        show: false,
      };
   },
    components: {
        SideBar: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Common/Profile/SideBar.vue') ),
        TopBar: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Common/Profile/TopBar.vue') ),
    },
    computed:{
   
    },
    mounted() {

        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 0) {
                $('#nav-profile').addClass("stiky");
            } else {
                $('#nav-profile').removeClass("stiky");
            }
        });
        
    }

}
</script>
<style lang="scss"></style>
