<template>
   <div class="bottom-header">
      <div class="row">
         <div class="col-sm-3 col-lg-3 col-6">
            <router-link to="/">
               <img style="width: 142px;" class="logo" src="@/assets/img/global/Logo.webp" alt="logo">
               <img class="logo1" src="@/assets/img/global/Logo1.png" alt="logo">
            </router-link>
         </div>
         <div class="col-sm-9 col-lg-9 d-none d-md-flex align-items-center">
            <ul class="links-list">
               <li v-for="item in list" :key="item.id">
                  <router-link :to="item.url" v-if="!item.sons">
                     {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                  </router-link>
                  <div class="dropdown" v-else>
                     <a class="dropdown-btn" href="#" target="_self">
                        {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                        <svg width="10" height="10" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12.0914 0L7 5.14365L1.90859 0L0 1.92818L7 9L14 1.92818L12.0914 0Z" fill="white" />
                        </svg>
                     </a>
                     <ul class="dropdown-lists">
                        <li v-for="j in item.sons" :key="j.id">
                           <router-link :to="j.url">
                              {{ this.$i18n.locale == 'ar' ? j.titleAr : j.titleEn }}
                           </router-link>
                           <span></span>
                        </li>
                     </ul>
                  </div>
               </li>
               <li v-if="$store.state.Userdata == ''">
                  <router-link to="/login">
                     <BtnMain
                     :title="$t('Login')"
                     :TextColor="'#225476'"
                     :hovercolor="'#fff'"
                     :BackgroundColor="'#fff'"
                     :hoverBackgroundColor="'transparent'"
                     :Border="'#fff'"
                     :hoverBorder="'#fff'" />
                  </router-link>
               </li>
               <li class="profile-link" @mouseleave="showProfileMenu = false" @mouseenter="showProfileMenu = true"
                  v-else>
                  <img class="user-icob" src="@/assets/img/Icon/user-icon.png" alt="">
                  <span class="name">Etmaam</span>
                  <ul class="profile-menu" :class="[showProfileMenu ? 'active' : '']">
                     <router-link to="/profile/my-profile">
                        <li>
                           {{ $t('Profile') }}
                        </li>
                     </router-link>
                     <router-link to="/profile/my-account">
                        <li>
                           {{ $t('account') }}
                        </li>
                     </router-link>
                     <router-link to="/profile/required-services">
                        <li>
                           {{ $t('Required_services') }}
                        </li>
                     </router-link>
                     <router-link to="/profile/required-packages">
                        <li>
                           {{ $t('Required_packages') }}
                        </li>
                     </router-link>
                     <router-link to="/profile/commercial">
                        <li>
                           {{ $t('Commercial_records') }}
                        </li>
                     </router-link>
                        <li @click="logOut()" style="font-weight: 700;font-size: 13px;">
                           {{ $t('LogOut') }}
                        </li>
                  </ul>
               </li>
            </ul>
         </div>
         <div class="col-md-4 col-6 d-flex d-md-none align-items-center justify-content-end">
            <div id="mobile-menu">
               <div class="side-icon" style="cursor: pointer" @click="openSideBar = !openSideBar">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <circle cx="2.38095" cy="2.38095" r="2.38095" fill="white" />
                     <circle cx="2.38095" cy="10.0001" r="2.38095" fill="white" />
                     <circle cx="2.38095" cy="17.6192" r="2.38095" fill="white" />
                     <circle cx="9.99814" cy="2.38095" r="2.38095" fill="white" />
                     <circle cx="9.99814" cy="10.0001" r="2.38095" fill="white" />
                     <circle cx="9.99814" cy="17.6192" r="2.38095" fill="white" />
                     <circle cx="17.6192" cy="2.38095" r="2.38095" fill="white" />
                     <circle cx="17.6192" cy="10.0001" r="2.38095" fill="white" />
                     <circle cx="17.6192" cy="17.6192" r="2.38095" fill="white" />
                  </svg>
               </div>
               <div class="overlay" @click="openSideBar = false" :class="[openSideBar ? 'open' : '']"></div>
               <div class="side-bar" :class="[openSideBar ? 'open' : '']">
                  <div class="d-flex justify-content-between align-items-center">
                     <div class="icon-bar" @click="openSideBar = !openSideBar">
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M19.5 7.5C19.5 7.79313 19.3799 8.07426 19.1661 8.28154C18.9523 8.48882 18.6623 8.60526 18.36 8.60526H1.64C1.33765 8.60526 1.04769 8.48882 0.833898 8.28154C0.620107 8.07426 0.5 7.79313 0.5 7.5C0.5 7.20687 0.620107 6.92574 0.833898 6.71846C1.04769 6.51118 1.33765 6.39474 1.64 6.39474H18.36C18.6623 6.39474 18.9523 6.51118 19.1661 6.71846C19.3799 6.92574 19.5 7.20687 19.5 7.5ZM1.64 2.71053H18.36C18.6623 2.71053 18.9523 2.59408 19.1661 2.3868C19.3799 2.17953 19.5 1.8984 19.5 1.60526C19.5 1.31213 19.3799 1.031 19.1661 0.823724C18.9523 0.616447 18.6623 0.5 18.36 0.5H1.64C1.33765 0.5 1.04769 0.616447 0.833898 0.823724C0.620107 1.031 0.5 1.31213 0.5 1.60526C0.5 1.8984 0.620107 2.17953 0.833898 2.3868C1.04769 2.59408 1.33765 2.71053 1.64 2.71053ZM18.36 12.2895H1.64C1.33765 12.2895 1.04769 12.4059 0.833898 12.6132C0.620107 12.8205 0.5 13.1016 0.5 13.3947C0.5 13.6879 0.620107 13.969 0.833898 14.1763C1.04769 14.3836 1.33765 14.5 1.64 14.5H18.36C18.6623 14.5 18.9523 14.3836 19.1661 14.1763C19.3799 13.969 19.5 13.6879 19.5 13.3947C19.5 13.1016 19.3799 12.8205 19.1661 12.6132C18.9523 12.4059 18.6623 12.2895 18.36 12.2895Z"
                              fill="white" />
                        </svg>
                     </div>
                     <div class="logo">
                        <router-link to="/">
                           <img width="100" src="@/assets/img/global/Logo.webp" alt="">
                        </router-link>
                     </div>
                  </div>
                  <div class="side-links">
                     <ul>
                        <li v-for="item in list" :key="item.id">
                           <router-link :to="item.url" v-if="!item.sons">
                              {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                           </router-link>
                           <div class="dropdown" v-else>
                              <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                 {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                              </button>
                              <ul class="dropdown-menu">
                                 <li v-for="j in item.sons" :key="j.id">
                                    <router-link class="dropdown-item":to="j.url">
                                       {{ this.$i18n.locale == 'ar' ? j.titleAr : j.titleEn }}
                                    </router-link>
                                 </li>
                              </ul>
                              </div>
                        </li>
                        <li v-if="$store.state.Userdata == ''">
                           <router-link to="/login">
                              <BtnMain :title="$t('Login')" :TextColor="'#225476'" :hovercolor="'#fff'"
                                 :BackgroundColor="'#fff'" :hoverBackgroundColor="'transparent'" :Border="'#fff'"
                                 :hoverBorder="'#fff'" />
                           </router-link>
                        </li>
                     </ul>
                  </div>
               </div>
               <!-- <div class="mobile-list" :class="[openSideBar ? 'open':'']">
               <ul>
                  <li v-for="item in list" :key="item.id">
                     <router-link :to="item.url" v-if="!item.sons">
                        {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                     </router-link>
                     <div class="dropdown" v-else>
                  <a class="dropdown-btn" href="#" target="_self">
                     {{ this.$i18n.locale == 'ar' ? item.titleAr : item.titleEn }}
                     <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0914 0L7 5.14365L1.90859 0L0 1.92818L7 9L14 1.92818L12.0914 0Z" fill="white"/>
                     </svg>
                  </a>
                  <ul class="dropdown-lists" >
                     <li v-for="j in item.sons" :key="j.id">
                        <router-link :to="j.url">
                           {{ this.$i18n.locale == 'ar' ? j.titleAr : j.titleEn }}
                        </router-link>
                        <span></span>
                     </li>
                  </ul>
               </div>
                  </li>
               </ul>
            </div> -->
            </div>
         </div>
      </div>
   </div>

</template>
<script>
import { defineAsyncComponent } from 'vue'
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
   data() {
      return {
         showProfileMenu: false,
         openSideBar: false,
         list: [
            {
               id: 1,
               titleEn: 'Home',
               titleAr: 'الصفحة الرئيسية',
               url: '/',
            },
            {
               id: 2,
               titleEn: 'About Us',
               titleAr: 'من نحن',
               url: '/',
               sons: [
                  {
                     id: 'son1',
                     titleAr: 'نبذة عنا',
                     titleEn: 'About Us',
                     url: '/about',
                  },
                  // {
                  //    id: 'son2',
                  //    titleAr: 'آخر الأخبار',
                  //    titleEn: 'Latest News',
                  // },
                  // {
                  //    id: 'son3',
                  //    titleAr: 'دليل الخدمات',
                  //    titleEn: 'Service Guide',
                  // },
                  {
                     id: 'son3',
                     titleAr: 'حساباتنا البنكية',
                     titleEn: 'Accounts Banks',
                     url: '/bank-accounts'
                  },
                  {
                     id: 'son4',
                     titleAr: 'سياسة الخصوصية',
                     titleEn: 'Privacy Policy',
                     url: '/privacy-policy',
                  },
                  {
                     id: 'son5',
                     titleAr: 'الشروط  و الأحكام',
                     titleEn: 'Terms And Conditions',
                     url: '/using-policy',
                  },
               ]
            },
            {
               id: 3,
               titleEn: 'Help Center',
               titleAr: 'مركز المساعدة',
               url: '',
               sons: [
                  {
                     id: 'son1',
                     titleEn: 'Contact',
                      titleAr: 'اتصل بنا',
                      url: '/contact',
                  },
                  {
                     id: 'son2',
                     titleAr: 'الأسئلة الشائعة',
                     titleEn: 'FAQ',
                     url: '/faqs',
                  },
                  {
                     id: 'son3',
                     titleAr: 'الوظائف',
                     titleEn: 'Careers',
                     url: '/jobs',
                  },
                  {
                     id: 'son3',
                     titleAr: 'مركز التحميلات',
                     titleEn: 'Downloads Center',
                     url: '/downloads-center',
                  },
                  {
                     id: 'son4',
                     titleAr: 'الامتياز التجاري',
                     titleEn: 'Commercial franchise',
                     url: '/commercial-franchise',
                  },


               ]
            },

            {
               id: 4,
               titleEn: 'Packages',
               titleAr: 'الباقات',
               url: '/packages',
            },
            {
               id: 5,
               titleEn: 'Blogs',
               titleAr: 'المدونة',
               url: '/blogs',
            },
            {
               id: 5,
               titleEn: 'Service Order',
               titleAr: 'طلب خدمة',
               url: '/services',
            },
            {
               id: 7,
               titleEn: 'Waht We Offer',
               titleAr: 'ماذا نقدم لكم',
               url: '',
               sons: [
                  {
                     id: 'son1',
                     titleAr: 'تأسيس الشركات',
                     titleEn: 'Establishing companies',
                     url: '/services/company-formation',
                  },
                  {
                     id: 'son2',
                     titleAr: 'خدمات المستثمرين',
                     titleEn: 'Investor services',
                     url: '/services/foreign-services',
                  },
                  {
                     id: 'son3',
                     titleAr: 'الخدمات القانونية',
                     titleEn: 'Legal services',
                     url: '/services/legal-services',
                  },
                  {
                     id: 'son4',
                     titleAr: 'برنامج حماية الأجور',
                     titleEn: 'Wage Protection Program',
                     url: '/services/wage-protection',
                  },
                  {
                     id: 'son5',
                     titleAr: 'تسجيل العلامات التجارية',
                     titleEn: 'Trademark registration',
                     url: '/services/trademark-registration',
                  },
                  {
                     id: 'son6',
                     titleAr: 'إدارة الخدمات الإلكترونية',
                     titleEn: 'Electronic services management',
                     url: '/services/electronic-services',
                  },
                
               ]
            },
            {
               id: 8,
               titleEn: 'Join Us',
               titleAr: 'انضم إلينا',
               url: '/join-us',
            }

         ]
      };
   },
   components: {
      BtnMain: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Global/BtnMain.vue')),
   },
   methods:{
      logOut(){
         cookie.remove('Token');
         cookie.remove('Userdata');
         cookie.remove('Companydata');
         window.location.reload();
      }
   },
   watch:{
    $route (to, from){
        this.openSideBar = false;
    }
} 
};
</script>
