<template>
    <div class="row ">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Register_order') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
        <div class="register-order-list">
            <div class="item" v-for="item in 5">
                <div class="d-flex align-items-center">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.11321 17.3039C0.97973 17.3221 0.84384 17.3083 0.71674 17.2636C0.58964 17.219 0.474971 17.1448 0.382187 17.0471C0.289404 16.9494 0.221164 16.8311 0.183091 16.7019C0.145018 16.5727 0.138204 16.4362 0.163211 16.3039V1.63387C0.140789 1.50277 0.149083 1.36825 0.187443 1.24089C0.225803 1.11353 0.293183 0.996799 0.384284 0.899884C0.475384 0.802968 0.587724 0.728505 0.71247 0.682348C0.837215 0.636191 0.970968 0.619598 1.10321 0.633875H12.8932C13.0255 0.619598 13.1592 0.636191 13.284 0.682348C13.4087 0.728505 13.521 0.802968 13.6121 0.899884C13.7032 0.996799 13.7706 1.11353 13.809 1.24089C13.8473 1.36825 13.8556 1.50277 13.8332 1.63387V16.3539C13.8581 16.4907 13.8494 16.6314 13.808 16.7642C13.7665 16.8969 13.6935 17.0176 13.5952 17.1159C13.4969 17.2142 13.3762 17.2872 13.2435 17.3286C13.1108 17.3701 12.97 17.3788 12.8332 17.3539L1.11321 17.3039ZM1.95321 2.30387H1.87321C1.87321 6.75387 1.87321 11.1039 1.87321 15.4639L1.99321 15.6739V15.6339H12.1432V8.05387C12.1432 6.17387 12.1432 4.30387 12.1432 2.43387L12.0332 2.23387V2.33387H1.95321V2.30387Z" fill="#0A3041"/>
                    <path d="M6.25292 11.6942C5.99181 11.6774 5.74768 11.5589 5.57292 11.3642L3.41292 9.20418C3.30325 9.09718 3.22341 8.96339 3.18132 8.81606C3.13923 8.66874 3.13633 8.51297 3.17292 8.36418C3.21395 8.22428 3.28948 8.09693 3.39257 7.99383C3.49566 7.89074 3.62301 7.81521 3.76292 7.77418H3.91292C4.04283 7.76812 4.17253 7.78998 4.29328 7.83828C4.41403 7.88658 4.52302 7.9602 4.61292 8.05418L6.21292 9.63418C7.30292 8.63418 8.32292 7.58418 9.35292 6.56418C9.43741 6.47691 9.53835 6.40723 9.64991 6.35917C9.76148 6.31112 9.88145 6.28563 10.0029 6.28418C10.1717 6.28553 10.3361 6.33769 10.4748 6.43386C10.6135 6.53003 10.72 6.66575 10.7805 6.82333C10.8409 6.98091 10.8525 7.15305 10.8137 7.3173C10.7749 7.48156 10.6875 7.63031 10.5629 7.74418L6.93292 11.3842C6.75483 11.5715 6.51113 11.6826 6.25292 11.6942Z" fill="#0A3041"/>
                  </svg>
                    <div class="title" style="margin-inline-start: 12px;">هذا النص مثال لعنوان يوضح خطوة بلسجل</div>
                </div>
                <div class="description">هذا النص مثال لنص يوضح شرح عن السجل و يكون عبارة عن عدة اسطر او عدة كلمات تكتب في هذا الحثل و يمتد لطول معين</div>
                <div class="date">5/5/2024</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      imageId:[],
    }
  },
  methods:{
    SetimageId(e){
      console.log(event.target.files);
      this.imageId.push(event.target.files[0])
    },
    downloadFile(file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      URL.revokeObjectURL(url);
    },
  }

};
</script>