<template>
   <div class="m-p">
      <MyProfile />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Profile',
components: {
  MyProfile: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/MyProfile.vue') ),
},
}
</script>