<template>
    <div class="packages-page">
    <Spinner />
    <SpecialPackage v-if="this.$store.state.showPopupForm == true"  />
      <div class="row">
        <div class="col-12">
          <div class="special-packges">
            <div class="title-special">
              <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29 56V31.7204M50.6433 31.7204V42.1607C50.6443 43.1789 50.3648 44.1779 49.8352 45.0485C49.3057 45.9191 48.5464 46.6279 47.6403 47.0975L31.4078 55.4065C30.6641 55.7919 29.8382 55.9931 29 55.9931C28.1618 55.9931 27.3359 55.7919 26.5922 55.4065L10.3597 47.0975C9.45363 46.6279 8.69435 45.9191 8.16476 45.0485C7.63518 44.1779 7.35566 43.1789 7.35671 42.1607V31.7204M37.5762 2.61196C38.25 2.23628 39.0091 2.03904 39.7811 2.03904C40.553 2.03904 41.3122 2.23628 41.986 2.61196L53.3487 8.9786C54.1534 9.43236 54.8229 10.091 55.2887 10.8873C55.7545 11.6836 56 12.5889 56 13.5108C56 14.4326 55.7545 15.338 55.2887 16.1342C54.8229 16.9305 54.1534 17.5892 53.3487 18.043L20.3968 36.5494C19.721 36.9338 18.9564 37.1359 18.1784 37.1359C17.4003 37.1359 16.6357 36.9338 15.9599 36.5494L4.6513 30.1827C3.84657 29.729 3.17709 29.0703 2.71127 28.274C2.24545 27.4777 2 26.5724 2 25.6506C2 24.7287 2.24545 23.8234 2.71127 23.0271C3.17709 22.2308 3.84657 21.5721 4.6513 21.1184L37.5762 2.61196ZM53.3487 30.1827C54.1534 29.729 54.8229 29.0703 55.2887 28.274C55.7545 27.4777 56 26.5724 56 25.6506C56 24.7287 55.7545 23.8234 55.2887 23.0271C54.8229 22.2308 54.1534 21.5721 53.3487 21.1184L20.4238 2.58498C19.7526 2.20166 18.9925 2 18.2189 2C17.4454 2 16.6853 2.20166 16.014 2.58498L4.6513 8.9786C3.84657 9.43236 3.17709 10.091 2.71127 10.8873C2.24545 11.6836 2 12.5889 2 13.5108C2 14.4326 2.24545 15.338 2.71127 16.1342C3.17709 16.9305 3.84657 17.5892 4.6513 18.043L37.6032 36.5494C38.2741 36.9337 39.0344 37.136 39.8081 37.136C40.5819 37.136 41.3421 36.9337 42.013 36.5494L53.3487 30.1827Z" stroke="#225476" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>
                {{ this.$i18n.locale == 'ar' ? 'قم بتصميم باقة خاصة بك !' :'Design your own package !' }}
              </span>
            </div>
            <button @click="this.$store.state.showPopupForm = true" class="btn-main" style="border-radius: 5px;font-size: 16px;">
              {{ this.$i18n.locale == 'ar' ? 'صمم باقتك' :'Design your package' }}
            </button>
           
          </div>
        </div>
        <div class="col-12 mb-5">
          <div class="banner">
            <div class="row">
              <div class="col-md-4 mb-3">
                <h1>   {{ this.$i18n.locale == 'ar' ? 'مستعد لتبدأ مع اتمام' :'Ready to start with completion' }}</h1>
                <h2> {{ this.$i18n.locale == 'ar' ? 'قم باختيار الباقة المناسبة لك' :'Choose the package that suits you' }}</h2>
                <p>
                  هذا النص مثال لنص يمكن ان يوضع في المكان يوضح شرح لفكرة
                </p>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4 mb-3">
                <h1>{{ this.$i18n.locale == 'ar' ? '14 يوم مجانا' :'14 days free!' }}</h1>
                <p>هذا النص مثال لنص يمكن ان يوضع في المكان يوضح شرح لفكرة</p>
                <button class="btn-main">{{this.$i18n.locale == 'ar' ? 'ابدأ تجربتك بشكل مجاني' :'Start your trial for free'  }}</button>
              </div>
            </div>
          </div>
          <div class="subscription">
            <button @click="slected = 'annual_price'" :class="slected == 'annual_price' ? 'active':''">{{ this.$i18n.locale == 'ar' ? 'اشتراك سنوي' : 'Annual Subscription' }}</button>
            <button @click="slected = 'semi_annual_price'" :class="slected == 'semi_annual_price' ? 'active':''">{{ this.$i18n.locale == 'ar' ? 'اشتراك نصف سنوي' :'Semi-Annual Subscription' }}</button>
            <!-- <button @click="slected = 'quarterly_price'" :class="slected == 'quarterly_price' ? 'active':''">{{ this.$i18n.locale == 'ar' ? 'اشتراك ربع سنوي' :'quartz-Annual Subscription' }}</button>
            <button @click="slected = 'monthly_price'" :class="slected == 'monthly_price' ? 'active':''">{{ this.$i18n.locale == 'ar' ? 'اشتراك شهري' :'monthly Subscription' }}</button> -->
          </div>
        </div>
        <div class="col-12">
          <ul class="nav nav-pills" id="pills-tab3" role="tablist">
            <swiper class="swiper d-block" :space-between="0" :speed="1000" :breakpoints="swiperOptions.breakpoints" >
          <swiper-slide v-for="item in PackagesCateg" :key="item.id"  role="presentation">
            <li
            class="nav-item "
             role="presentation">
              <button @click="PackagesCategId(item.id)" :data-index="'Packages'+item.id"
              :class="['nav-link py-3', { 'active': item.id == selectedPackageId }]"
                 id="v-pills-Packages1-tab" data-bs-toggle="pill"
                :data-bs-target="'#v-pills-Packages'+item.id" type="button" role="tab" :aria-controls="'v-pills-Packages'+item.id"
                aria-selected="true">
                <span class=" fs-sm-4">{{ item.name }}</span>
              </button>
            </li>
          </swiper-slide>
        </swiper>
          </ul>
          <div class="tab-content mt-3" id="pills-tabContent" >
            <div class="tab-pane fade show active" id="v-pills-Packages" role="tabpanel"
              aria-labelledby="v-pills-Packages1-tab">
              <Service_packages
              :packageId="selectedPackageId"
              :annual_price="slected"
              />
            </div>
          </div>
        </div>
      </div>
      </div>

  </template>

  <script>
    import Spinner from '../Global/Spinner.vue';
    import { defineAsyncComponent } from 'vue'
  export default {
    components: {
      Spinner,
      Service_packages: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Service_packages.vue') ),
      Wage_packages: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Wage_packages.vue') ),
      SpecialPackage: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/SpecialPackage.vue') ),
    },

    data() {
      return {
        swiperOptions: {
        breakpoints: {

          300: {  // when window width from 300px to 576px
            slidesPerView: 2,
            spaceBetween: 10
          },
          576: {  // when window width from 576px to 767px
            slidesPerView: 2,
            spaceBetween: 50
          },
          767: { // when window width from 767px to 991px
            slidesPerView: 3,
            spaceBetween: 50
          },

          991: { // when window width from 991px to 1200px
            slidesPerView: 4,
            spaceBetween: 50
          },
          1200: { // when window width from 1200px to higher
            slidesPerView: 5,
            spaceBetween: 12
          },
        },
      },
        slected:'annual_price',
        PackagesCateg:[],
        packagesCards:[],
        selectedPackageId: 1,
      };
    },

   mounted(){
    this.$store.dispatch('SETLOADER',true);
    this.$store.dispatch('PackagesCateg').then(r => {
            this.PackagesCateg = this.$store.state.PackagesCateg;
             this.$store.dispatch('SETLOADER',false);
        })
   },

   methods:{
    PackagesCategId(id) {
      this.selectedPackageId = id;
    }
   }

  }
  </script>
