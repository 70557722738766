<template>
  <div class="contact-page">
    <Location :phone_number_1="phone_number_1" :phone_number_2="phone_number_2" :email_address="email_address"
      :address_central_1="address_central_1" 
      :address_central_2="address_central_2" 
      :address_eastern_1="address_eastern_1" 
      :address_eastern_2="address_eastern_2" 
      :address_southern_1="address_southern_1"
      :address_southern_2="address_southern_2" />
    <FormContact />
    <GeoLocation 
    :Riyadh_location="Riyadh_location" 
    :Khobar_location="Khobar_location"
    :Muhayil_location="Muhayil_location"
     />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FormContact: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Contact/FormContact')),
    Location: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Contact/Location.vue')),
    GeoLocation: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Contact/GeoLocation.vue')),
  },
  data() {
    return {
      phone_number_1: '',
      phone_number_2: '',
      email_address: '',
      address_central_1: '',
      address_central_2: '',
      address_eastern_1: '',
      address_eastern_2: '',
      address_southern_1: '',
      address_southern_2: '',
      Riyadh_location:'',
      Khobar_location:'',
      Muhayil_location:'',
    };
  },
  methods: {
    handleStaticFooter(StaticContent) {
      StaticContent.forEach((item) => {
        if (item.key == 'phone_number_1') {
          this.phone_number_1 = item.value;
        }
        if (item.key == 'phone_number_2') {
          this.phone_number_2 = item.value;
        }
        if (item.key == 'email_address') {
          this.email_address = item.value;
        }
        if (item.key == 'address_central_1') {
          this.address_central_1 = item.value;
        }
        if (item.key == 'address_central_2') {
          this.address_central_2 = item.value;
        }
        if (item.key == 'address_eastern_1') {
          this.address_eastern_1 = item.value;
        }
        if (item.key == 'address_eastern_2') {
          this.address_eastern_2 = item.value;
        }
        if (item.key == 'address_southern_1') {
          this.address_southern_1 = item.value;
        }
        if (item.key == 'address_southern_2') {
          this.address_southern_2 = item.value;
        }
        if (item.key == 'Riyadh_location') {
          this.Riyadh_location = item.value;
        }
        if (item.key == 'Khobar_location') {
          this.Khobar_location = item.value;
        }
        if (item.key == 'Muhayil_location') {
          this.Muhayil_location = item.value;
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch('GetStaticContent', 1).then(r => {
      this.handleStaticFooter(this.$store.state.StaticContent);
    });
  }
}
</script>