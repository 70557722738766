<template>
    <div class="row ">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Correspondence') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
        <div class="correspondence">
            <div class="row mb-3" v-for="item in 2">
                <div class="col-md-5"></div>
                <div class="col-md-7 d-flex mb-2">
                    <div class="card-blue">
                        <div class="content">
                            هذا النص مثال لنص يوضع في المكان يوضح المراسلة بين مزود الخدمة عندما يرسلها لطالب الخدمة
                        </div>
                        <div class="date">
                            4/4/2024
                        </div>
                    </div>
                    <div class="logo">
                        <img width="32" src="@/assets/img/Icon/provider.png" alt="">
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card-white">
                        <div class="content">
                            هذا النص هو مثال لنص يوضح ترك رسالة معينة
                        </div>
                        <div class="date">
                            4/4/2024
                        </div>
                    </div>
                </div>
                <div class="col-md-7"></div>
            </div>
            <div class="post-correspondence">
                <input type="text" name="correspondence" placeholder="اكتب رسالة...">
                <button class="btn-main">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0095238 17.5L20 9L0.0095238 0.5L0 7.11111L14.2857 9L0 10.8889L0.0095238 17.5Z" fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props:{
    chat_id:Number,
    },
  methods:{
  }

};
</script>