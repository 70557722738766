<template>
    <renewPackage v-if="this.$store.state.renewPackage == true" />
    <upgradePackage v-if="this.$store.state.upgradePackage == true" />
    <div class="required-item">
        <div class="container">
            <h2 class="title-profile mt-0">{{ $route.name }}</h2>
            <div class="invoices">
                <div class="head-section-profile">
                    <div>
                        <select name="" id="">
                            <option value="">{{ this.$i18n.locale == 'ar' ? 'التصنيف حسب...' : 'Filter By...' }}
                            </option>
                        </select>
                    </div>

                    <div class="d-flex">
                        <div>
                            <ul class="nav nav-pills nav-pills-order-details justify-content-end"
                                id="pills-tab-invoices" role="tablist">
                                <li class="nav-item active" role="presentation">
                                    <button data-index="row-view" class="nav-link active" id="v-pills-row-view-tab"
                                        data-bs-toggle="pill" data-bs-target="#v-pills-row-view" type="button"
                                        role="tab" aria-controls="v-pills-P-D-1" aria-selected="true">
                                        <span class=" fs-sm-4">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 1.90909C0 0.854729 0.854729 0 1.90909 0H12.0909C13.1453 0 14 0.854729 14 1.90909V1.90909C14 2.96345 13.1453 3.81818 12.0909 3.81818H1.90909C0.854729 3.81818 0 2.96345 0 1.90909V1.90909Z"
                                                    fill="#0A3041" />
                                                <path
                                                    d="M0 7C0 5.94564 0.854729 5.09091 1.90909 5.09091H12.0909C13.1453 5.09091 14 5.94564 14 7V7C14 8.05436 13.1453 8.90909 12.0909 8.90909H1.90909C0.854729 8.90909 0 8.05436 0 7V7Z"
                                                    fill="#0A3041" />
                                                <path
                                                    d="M0 12.0909C0 11.0365 0.854729 10.1818 1.90909 10.1818H12.0909C13.1453 10.1818 14 11.0365 14 12.0909V12.0909C14 13.1453 13.1453 14 12.0909 14H1.90909C0.854729 14 0 13.1453 0 12.0909V12.0909Z"
                                                    fill="#0A3041" />
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                                <li class="nav-item mx-2" role="presentation">
                                    <button data-index="card-view" class="nav-link" id="v-pills-card-view-tab"
                                        data-bs-toggle="pill" data-bs-target="#v-pills-card-view" type="button"
                                        role="tab" aria-controls="v-pills-card-view" aria-selected="true">
                                        <span class=" fs-sm-4">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 0.999999C0 0.447714 0.447715 0 1 0H5.58824C6.14052 0 6.58824 0.447715 6.58824 1V5.58824C6.58824 6.14052 6.14052 6.58824 5.58824 6.58824H0.999999C0.447714 6.58824 0 6.14052 0 5.58824V0.999999Z"
                                                    fill="#F3F7F9" />
                                                <path
                                                    d="M7.41176 0.999999C7.41176 0.447714 7.85948 0 8.41176 0H13C13.5523 0 14 0.447715 14 1V5.58824C14 6.14052 13.5523 6.58824 13 6.58824H8.41176C7.85948 6.58824 7.41176 6.14052 7.41176 5.58824V0.999999Z"
                                                    fill="#F3F7F9" />
                                                <path
                                                    d="M7.41176 8.41176C7.41176 7.85948 7.85948 7.41176 8.41176 7.41176H13C13.5523 7.41176 14 7.85948 14 8.41176V13C14 13.5523 13.5523 14 13 14H8.41176C7.85948 14 7.41176 13.5523 7.41176 13V8.41176Z"
                                                    fill="#F3F7F9" />
                                                <path
                                                    d="M0 8.41176C0 7.85948 0.447715 7.41176 1 7.41176H5.58824C6.14052 7.41176 6.58824 7.85948 6.58824 8.41176V13C6.58824 13.5523 6.14052 14 5.58824 14H0.999999C0.447714 14 0 13.5523 0 13V8.41176Z"
                                                    fill="#F3F7F9" />
                                            </svg>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <button class="btn-main" style="margin-right: 50px;">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                                    fill="white" />
                            </svg>
                            {{ $t('Request_a_service') }}
                        </button>
                    </div>
                </div>

                <div class="tab-content p-d mt-3" id="pills-tabContent">
                    <div class="tab-pane fade show active row-view" id="v-pills-row-view" role="tabpanel"
                        aria-labelledby="v-pills-row-view-tab">
                        <div class="row">
                            <div class="col-md-4" v-for="(item,index) in 6" :key="index" >
                                <div class="card-pack-req silver" :class="[ index == 0 || index == 3 ? 'silver': '',
                                   index == 1 || index == 4  ? 'gold': '',index == 2 || index == 5 ? 'platinum': '',
                                ]">
                                   
                                    <div class="menu" @click="show = -1" v-if="show == index">
                                        <div @click="this.$store.state.renewPackage = true"> 
                                        {{ $t('Package_renewal') }}
                                        </div>
                                        <div @click="this.$store.state.upgradePackage = true"> 
                                            {{ $t('Package_upgrade') }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center"  
                                    >
                                    <h2 class="name" @click="showmenu(index)">
                                    {{ $t('Silver_package') }} 
                                    </h2>
                                        <svg @click="showmenu(index)" style="cursor: pointer;margin-top: -10px;" width="18" height="5" viewBox="0 0 18 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 2.5C14 3.60457 14.8954 4.5 16 4.5C17.1046 4.5 18 3.60457 18 2.5C18 1.39543 17.1046 0.5 16 0.5C14.8954 0.5 14 1.39543 14 2.5Z" fill="#0A3041"/>
                                            <path d="M7 2.5C7 3.60457 7.89543 4.5 9 4.5C10.1046 4.5 11 3.60457 11 2.5C11 1.39543 10.1046 0.5 9 0.5C7.89543 0.5 7 1.39543 7 2.5Z" fill="#0A3041"/>
                                            <path d="M8.66252e-07 2.5C8.17969e-07 3.60457 0.895431 4.5 2 4.5C3.10457 4.5 4 3.60457 4 2.5C4 1.39543 3.10457 0.5 2 0.5C0.895431 0.5 9.14534e-07 1.39543 8.66252e-07 2.5Z" fill="#0A3041"/>
                                        </svg>
                                    </div>
                               
                                <div class="data-list">
                                    <div class="item">
                                        <div class="key">{{ $t('commercial_register')}}</div>
                                        <div class="value">125698456</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('Release_date')}}</div>
                                        <div class="value">22 فبراير 2022</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('End_date')}}</div>
                                        <div class="value" style="color: #CE4747;" >25 فبراير 2022</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('Payment_cycle')}}</div>
                                        <div class="value">نصف سنوي</div>
                                    </div>
                                    <div class="item">
                                        <div class="key">{{ $t('Monthly_installment')}}</div>
                                        <div class="value">3600 ر.س</div>
                                    </div>
                                </div>
                                    <div class="bottom-card">
                                        <div class="status review">{{ $t('In_process') }}</div>
                                        <router-link to="">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.31779 11.6027L0.373973 6.53275C0.299066 6.45593 0.245883 6.37271 0.214422 6.28309C0.182962 6.19347 0.167481 6.09745 0.16798 5.99502C0.16798 5.8926 0.183711 5.79658 0.215172 5.70696C0.246632 5.61733 0.299566 5.53412 0.373973 5.4573L5.31779 0.387326C5.45512 0.246494 5.6269 0.173005 5.83314 0.16686C6.03938 0.160714 6.21716 0.234203 6.36647 0.387326C6.51629 0.528159 6.59444 0.704328 6.60093 0.915833C6.60742 1.12734 6.53551 1.30965 6.3852 1.46277L2.71479 5.22684H11.0856C11.2978 5.22684 11.4758 5.30059 11.6197 5.44808C11.7635 5.59557 11.8351 5.77788 11.8346 5.99502C11.8346 6.21267 11.763 6.39524 11.6197 6.54273C11.4763 6.69022 11.2983 6.76371 11.0856 6.7632H2.71479L6.3852 10.5273C6.52253 10.6681 6.59444 10.8473 6.60093 11.065C6.60742 11.2826 6.53551 11.4619 6.3852 11.6027C6.24787 11.7564 6.07309 11.8332 5.86086 11.8332C5.64862 11.8332 5.4676 11.7564 5.31779 11.6027Z"
                                                    fill="#225476" />
                                            </svg>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-5">
                                <div class="pagination">
                                    <svg @click="prevPage" :class="[index == 1 ? 'disabled' : '']" class="prev "
                                        width="8" height="12" viewBox="0 0 8 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0.404541 6.87983C0.145501 6.64436 0 6.32515 0 5.99234C0 5.65953 0.145501 5.34033 0.404541 5.10485L5.62038 0.367661C5.87988 0.132173 6.23178 -7.8473e-05 6.59868 3.49332e-08C6.78034 3.89081e-05 6.96023 0.0325644 7.12805 0.0957197C7.29587 0.158875 7.44835 0.251423 7.57678 0.36808C7.70521 0.484737 7.80707 0.623218 7.87656 0.775616C7.94604 0.928015 7.98178 1.09135 7.98174 1.25629C7.98169 1.42122 7.94587 1.58454 7.87631 1.73691C7.80674 1.88928 7.70481 2.02772 7.57632 2.14432L3.33891 5.99234L7.57724 9.84037C7.70942 9.95617 7.81487 10.0947 7.88744 10.2479C7.96002 10.4011 7.99826 10.5659 7.99994 10.7326C8.00162 10.8994 7.96671 11.0648 7.89724 11.2191C7.82777 11.3735 7.72513 11.5138 7.59531 11.6317C7.4655 11.7497 7.3111 11.843 7.14113 11.9063C6.97117 11.9695 6.78904 12.0013 6.60537 12C6.4217 11.9986 6.24017 11.964 6.07137 11.8983C5.90258 11.8325 5.74989 11.7369 5.62222 11.617L0.402697 6.87983H0.404541Z"
                                            fill="white" />
                                    </svg>
                                    <span class="current">
                                        {{ index }}
                                    </span>
                                    <span style="margin: 0 5px;">/</span>
                                    <span class="pageLength">{{ pageLength }}</span>
                                    <svg @click="nextPage" :class="[index == pageLength ? 'disabled' : '']" class="next"
                                        width="8" height="12" viewBox="0 0 8 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M7.59546 5.12017C7.8545 5.35564 8 5.67485 8 6.00766C8 6.34047 7.8545 6.65967 7.59546 6.89515L2.37962 11.6323C2.12012 11.8678 1.76822 12.0001 1.40132 12C1.21966 12 1.03977 11.9674 0.87195 11.9043C0.704126 11.8411 0.551648 11.7486 0.423219 11.6319C0.29479 11.5153 0.192926 11.3768 0.123444 11.2244C0.0539621 11.072 0.0182218 10.9087 0.0182647 10.7437C0.0183076 10.5788 0.0541328 10.4155 0.123694 10.2631C0.193255 10.1107 0.29519 9.97229 0.42368 9.85568L4.66109 6.00766L0.422756 2.15963C0.290581 2.04384 0.18513 1.9053 0.112556 1.75211C0.0399819 1.59892 0.00173953 1.43414 5.72359e-05 1.26738C-0.00162506 1.10063 0.0332885 0.935241 0.10276 0.780869C0.172232 0.626497 0.27487 0.486232 0.404687 0.368259C0.534504 0.250286 0.688899 0.156967 0.858864 0.0937468C1.02883 0.0305268 1.21096 -0.00132692 1.39463 4.34928e-05C1.5783 0.00141295 1.75983 0.0359789 1.92863 0.101725C2.09742 0.167471 2.25011 0.263081 2.37777 0.382974L7.5973 5.12017L7.59546 5.12017Z"
                                            fill="#909090" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show card-view" id="v-pills-card-view" role="tabpanel"
                        aria-labelledby="v-pills-card-view-tab">
                        <div class="invoices-tabel">
                            <div class="row head-tabel">
                                <div class="col-md-3">
                                    {{ this.$i18n.locale == 'ar' ? `رقم الفاتورة` : `Invoice Number` }}
                                </div>
                                <div class="col-md-3">
                                    {{ this.$i18n.locale == 'ar' ? `التاريخ` : `Date` }}
                                </div>
                                <div class="col-md-3">
                                    {{ this.$i18n.locale == 'ar' ? `المبلغ` : `Amount` }}
                                </div>
                                <div class="col-md-3">
                                    {{ this.$i18n.locale == 'ar' ? `التحميل` : `Download` }}
                                </div>
                            </div>
                            <div class="row body-tabel" v-for="item in 4">
                                <div class="col-md-3">
                                    4458758
                                </div>
                                <div class="col-md-3">
                                    22 فبراير 2022
                                </div>
                                <div class="col-md-3">
                                    250 ريال سعودي
                                </div>
                                <div class="col-md-3 text-center">
                                    <a href="" download="">
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.64142 8.85858C6.56332 8.93668 6.43668 8.93668 6.35858 8.85858L2.88896 5.38896C2.81182 5.31182 2.81073 5.1871 2.8865 5.10862L3.65862 4.30893C3.7363 4.22848 3.86485 4.22735 3.94392 4.30642L5.40858 5.77108C5.53457 5.89707 5.75 5.80784 5.75 5.62966V0.2C5.75 0.0895427 5.83954 0 5.95 0H7.05C7.16046 0 7.25 0.089543 7.25 0.2V5.62966C7.25 5.80784 7.46543 5.89707 7.59142 5.77108L9.05608 4.30642C9.13515 4.22735 9.2637 4.22848 9.34138 4.30893L10.1135 5.10862C10.1893 5.1871 10.1882 5.31182 10.111 5.38896L6.64142 8.85858ZM2 12C1.5875 12 1.2345 11.8532 0.941 11.5597C0.6475 11.2662 0.5005 10.913 0.5 10.5V8.45C0.5 8.33954 0.589543 8.25 0.7 8.25H1.8C1.91046 8.25 2 8.33954 2 8.45V10.3C2 10.4105 2.08954 10.5 2.2 10.5H10.8C10.9105 10.5 11 10.4105 11 10.3V8.45C11 8.33954 11.0895 8.25 11.2 8.25H12.3C12.4105 8.25 12.5 8.33954 12.5 8.45V10.5C12.5 10.9125 12.3533 11.2657 12.0598 11.5597C11.7663 11.8537 11.413 12.0005 11 12H2Z"
                                                fill="#0A3041" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
    data() {
        return {
            index: 1,
            pageLength: 5,
            show:-1
        }
    },
    components: {
        renewPackage: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Profile/modals/RenewPackage.vue')),
        upgradePackage: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Profile/modals/upgradePackage.vue')),
    },
    methods: {
        showmenu(d){
            if(this.show == -1){
                this.show = d
            }
            else{
                this.show = -1
            }
        },
        prevPage() {
            if (this.index != 1) {
                this.index--
            }
        },
        nextPage() {
            if (this.index != this.pageLength) {
                this.index++
            }
        }

    }
};
</script>
