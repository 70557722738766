<template>
    <div class="commercial">
        <div class="container">
            <div class="main-title">
                <p>{{ this.$i18n.locale == 'ar' ? 'الامتياز التجاري' : 'CommercialFranchise' }}</p>
            </div>

            <div class="banner mb-5">
                <div class="row align-items-center">
                    <div class="col-md-6 text-content">
                        <h1 class="title">
                            {{ this.$i18n.locale == 'ar' ?
                                `انجز اعمالك بكل سهولة !`
                                :
                                `Get your work done with ease!`
                            }}
                        </h1>
                        <p class="mt-4">
                      

                            {{ this.$i18n.locale == 'ar' ?
                                `اتمام لخدمات الأعمال تمثل مركز الابتكار في عالم الفرص التجارية. نحن لا نقدم فقط امتيازًا تجاريًا، بل نوفر منظومة متكاملة ترسخ مكانتك في ساحة الأعمال الحديثة. مع اتمام، تحصل على شراكة استراتيجية تفتح لك آفاق التميز والابتكار، بدعم من فريق متخصص يقدم الاستشارات الاستراتيجية، الدعم اللوجستي، والحلول التسويقية الذكية التي تضمن استدامة نموك. نعتقد بأن النجاح يتحقق بالتعاون والشراكة، لذا نعمل معكم جنبًا إلى جنب لتحقيق الريادة في مجالكم التجاري، مع تقديم فرص امتياز واقعية تتجاوز التوقعات.`
                                :
                                `Etmam Business Services represents the center of innovation in the world of business opportunities. We do not just provide a business franchise, but rather we provide an integrated system that consolidates your position in the modern business arena. With Etmam, you get a strategic partnership that opens up horizons of excellence and innovation for you, with the support of a specialized team that provides strategic consultations, logistical support, and smart marketing solutions that ensure the sustainability of your growth. We believe that success is achieved through cooperation and partnership, so we work side by side with you to achieve leadership in your commercial field, while providing realistic franchise opportunities that exceed expectations.`
                            }}
                        </p>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center img-content">
                        <img src="@/assets/img/commercial-franchise/banner.png" height="200" width="287"
                            alt="UsingPolicy">
                    </div>
                </div>
            </div>

            <div>
                <h1 class="top-title ">
                    {{ this.$i18n.locale == 'ar' ?
                    `ما هي آلية الطلب !`
                    :
                    `What is the request mechanism?`
                     }}

               </h1>

                <div class="row mt-5 mb-5">
                    <div class="col-sm-4 marg"  >
                        <div class="item">
                            <div class="img">
                                <img src="@/assets/img/commercial-franchise/card1.png">
                            </div>

                            <h2 class="title">
                             {{ this.$i18n.locale == 'ar' ?
                            `إملأ المعلومات الشخصية`
                            :
                            `Fill in the personal information`
                            }}
                            </h2>
                            <p>
                                {{ this.$i18n.locale == 'ar' ?
                            `ندعوك لتعبئة معلوماتك الشخصية بكل دقة ووضوح . حيث أننا نحرص على حماية بياناتك وتأمينها بما يتماشى مع أفضل معايير الخصوصية. شاركنا تفاصيلك اليوم واستمتع بتجربة مميزة .`
                            :
                            `We invite you to fill out your personal information accurately and clearly. We are keen to protect and secure your data in line with the best privacy standards. Share your details with us today and enjoy a unique experience.`
                            }}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4 marg"  >
                        <div class="item">
                            <div class="img">
                                <img src="@/assets/img/commercial-franchise/card2.png">
                            </div>
                            <h2 class="title">
                                {{ this.$i18n.locale == 'ar' ?
                            ` أجب عن الأسئلة المطروحة`
                            :
                            `Answer the questions asked`
                            }}
                            </h2>
                            <p>
                                {{ this.$i18n.locale == 'en' ?
                            `Please take some time to answer the questions below. Your accurate and complete answers are essential. We assure you that all the data you provide will be treated with complete confidentiality and high security.`
                            :
                            `يرجى تكريس بعض الوقت للإجابة عن الأسئلة المطلوبة أدناه. إجاباتك الدقيقة والمكتملة ضرورية . نؤكد لك أن جميع البيانات التي تقدمها ستُعالج بسرية تامة وأمان عالي.`
                            }}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4 marg" >
                        <div class="item">
                            <div class="img">
                                <img src="@/assets/img/commercial-franchise/card3.png">
                            </div>
                            <h2 class="title">
                                {{ this.$i18n.locale == 'ar' ?
                            `أتمم ارسال طلبك`
                            :
                            `Complete sending your request`
                            }}
                            </h2>
                            <p>
                                {{ this.$i18n.locale == 'ar' ?
                            `لإتمام العملية، الرجاء التأكد من إدخال كل البيانات المطلوبة بشكل دقيق. فور اكتمال هذه الخطوة، اضغط على زر الإرسال لتبدأ خطواتنا في معالجة طلبك والتواصل معك في أقرب وقت ممكن.`
                            :
                            `To complete the process, please ensure that you enter all required data accurately. Once this step is completed, click the submit button to begin processing your request and contacting you as soon as possible.`
                            }}
                            </p>
                        </div>
                    </div>
                </div>


            </div>

            <div class="frame">
                <div class="row" style="transform: translateY(-20px);">
                    <div class="col-md-6 img-frame">
                        <img src="@/assets/img/commercial-franchise/img-frame.png">
                    </div>
                    <div class="col-md-6 text-frame">
                        <h1>
                    {{ this.$i18n.locale == 'ar' ?
                    `طلب الامتياز التجاري`
                    :
                    `Commercial franchise application`
                     }}
                            <p class="p-etmmam">
                     {{ this.$i18n.locale == 'ar' ?
                    `من إتمام !`
                    :
                    `from Etmaam`
                     }}</p>
                        </h1>
                        <p>
                    {{ this.$i18n.locale == 'ar' ?
                    `توسع في عالم الأعمال مع امتياز 'إتمام' التجاري. اغتنم فرصة النمو والتطور وقدم طلبك اليوم لتكون جزءًا من قصة نجاح تبدأ بخطوة واعدة.`
                    :
                    `Expand into the business world with the 'Etmam' franchise. Seize the opportunity to grow and develop and apply today to be part of a success story that begins with a promising step.`
                     }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <VerticalStepper />

            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    name: 'CommercialFranchise Page',
    data() {
        return {

        }
    },

    components: {
        VerticalStepper: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/CommercialFranchise/VerticalStepper.vue') ),

    },





}
</script>
