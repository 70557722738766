<template>
    <div class="special-package" :class="[this.$store.state.showPopupForm ? `open` : ``]">
        <div class="overlay" @click="this.$store.state.showPopupForm = false"></div>
        <div class="card-package">
            <div class="row question">
                <div class="col-12">
                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27 52V29.5189M47.0401 29.5189V39.1858C47.0411 40.1286 46.7822 41.0536 46.2919 41.8597C45.8015 42.6659 45.0985 43.3221 44.2595 43.7569L29.2295 51.4505C28.5408 51.8073 27.7761 51.9936 27 51.9936C26.2239 51.9936 25.4592 51.8073 24.7705 51.4505L9.74048 43.7569C8.90151 43.3221 8.19847 42.6659 7.70811 41.8597C7.21776 41.0536 6.95894 40.1286 6.95992 39.1858V29.5189M34.9409 2.56663C35.5648 2.21878 36.2677 2.03614 36.9825 2.03614C37.6973 2.03614 38.4001 2.21878 39.024 2.56663L49.5451 8.46167C50.2902 8.88182 50.9101 9.4917 51.3414 10.229C51.7727 10.9663 52 11.8046 52 12.6581C52 13.5117 51.7727 14.35 51.3414 15.0873C50.9101 15.8246 50.2902 16.4344 49.5451 16.8546L19.0341 33.9902C18.4083 34.3461 17.7004 34.5333 16.98 34.5333C16.2596 34.5333 15.5516 34.3461 14.9259 33.9902L4.45491 28.0951C3.70979 27.675 3.0899 27.0651 2.65858 26.3278C2.22727 25.5905 2 24.7522 2 23.8987C2 23.0451 2.22727 22.2068 2.65858 21.4695C3.0899 20.7322 3.70979 20.1224 4.45491 19.7022L34.9409 2.56663ZM49.5451 28.0951C50.2902 27.675 50.9101 27.0651 51.3414 26.3278C51.7727 25.5905 52 24.7522 52 23.8987C52 23.0451 51.7727 22.2068 51.3414 21.4695C50.9101 20.7322 50.2902 20.1224 49.5451 19.7022L19.0591 2.54165C18.4376 2.18672 17.7338 2 17.0175 2C16.3013 2 15.5975 2.18672 14.976 2.54165L4.45491 8.46167C3.70979 8.88182 3.0899 9.4917 2.65858 10.229C2.22727 10.9663 2 11.8046 2 12.6581C2 13.5117 2.22727 14.35 2.65858 15.0873C3.0899 15.8246 3.70979 16.4344 4.45491 16.8546L34.9659 33.9902C35.5871 34.3461 36.2911 34.5333 37.0075 34.5333C37.724 34.5333 38.4279 34.3461 39.0491 33.9902L49.5451 28.0951Z"
                            stroke="#225476" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg class="close" @click="this.$store.state.showPopupForm = false" width="12" height="12"
                        viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.6561 1.33776L6.99387 6L11.6561 10.6622C11.788 10.7941 11.8621 10.973 11.8621 11.1595C11.8621 11.3461 11.788 11.525 11.6561 11.6569C11.5242 11.7887 11.3453 11.8628 11.1588 11.8628C10.9723 11.8628 10.7934 11.7887 10.6615 11.6569L5.99926 6.99461L1.33701 11.6569C1.20512 11.7887 1.02623 11.8628 0.839708 11.8628C0.653182 11.8628 0.474295 11.7887 0.342401 11.6569C0.210507 11.525 0.136411 11.3461 0.136411 11.1595C0.136411 10.973 0.210508 10.7941 0.342401 10.6622L5.00464 6L0.342402 1.33776C0.210508 1.20586 0.136411 1.02698 0.13641 0.840451C0.13641 0.653925 0.210507 0.47504 0.342401 0.343146C0.474295 0.211252 0.653181 0.137155 0.839707 0.137154C1.02623 0.137155 1.20512 0.211252 1.33701 0.343145L5.99926 5.00539L10.6615 0.343146C10.7934 0.211252 10.9723 0.137155 11.1588 0.137155C11.3453 0.137155 11.5242 0.211252 11.6561 0.343146C11.788 0.47504 11.8621 0.653925 11.8621 0.840452C11.8621 1.02698 11.788 1.20586 11.6561 1.33776Z"
                            fill="#0A3041" />
                    </svg>
                </div>
                <div class="col-12">
                    <h2 class="title">{{ this.$i18n.locale == `ar` ? `صمم باقتك !` : `Design your package !` }}</h2>
                </div>
                <div class="col-12">
                    <div class="row" v-if="index == 1">
                        <div class="col-md-6">
                            <h3> {{ this.$i18n.locale == `ar` ? `قم باضافة الخدمات المطلوبة` : `Add the required
                                services` }}</h3>
                        </div>
                        <div class="col-md-6"></div>
                        <div class="col-md-5">
                            <select name="department_service" v-model="department_service">
                                <option value="">قسم الخدمة</option>
                                <option value="خدمات وزارة الصحة">خدمات وزارة الصحة</option>
                            </select>
                        </div>
                        <div class="col-md-5">
                            <select name="kind_service" v-model="kind_service">
                                <option value="">نوع الخدمة</option>
                                <option value="اصدار شهادة ممارسة المهنة">اصدار شهادة ممارسة المهنة</option>
                                <option value="اصدار ترخيص نهائي">اصدار ترخيص نهائي</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <button class="btn-main" @click="handelAdd">{{ $t(`Add`) }}</button>
                        </div>
                        <div class="col-12 " v-for="(item, index) in ListAdded" :key="index">
                            <div class="item-service">
                                <div>
                                    <div class="department">{{ item.department }}</div>
                                    <div class="kind">{{ item.kind }}</div>
                                </div>
                                <svg @click="this.delete(index)" width="16" height="17" viewBox="0 0 16 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 2.1C4 1.67565 4.16857 1.26869 4.46863 0.968629C4.76869 0.668571 5.17565 0.5 5.6 0.5H10.4C10.8243 0.5 11.2313 0.668571 11.5314 0.968629C11.8314 1.26869 12 1.67565 12 2.1V3.7H15.2C15.4122 3.7 15.6157 3.78429 15.7657 3.93431C15.9157 4.08434 16 4.28783 16 4.5C16 4.71217 15.9157 4.91566 15.7657 5.06569C15.6157 5.21571 15.4122 5.3 15.2 5.3H14.3448L13.6512 15.0136C13.6225 15.4173 13.4418 15.7951 13.1457 16.0709C12.8495 16.3467 12.4599 16.5 12.0552 16.5H3.944C3.53931 16.5 3.14965 16.3467 2.85351 16.0709C2.55736 15.7951 2.37673 15.4173 2.348 15.0136L1.656 5.3H0.8C0.587827 5.3 0.384344 5.21571 0.234315 5.06569C0.0842854 4.91566 0 4.71217 0 4.5C0 4.28783 0.0842854 4.08434 0.234315 3.93431C0.384344 3.78429 0.587827 3.7 0.8 3.7H4V2.1ZM5.6 3.7H10.4V2.1H5.6V3.7ZM3.2592 5.3L3.9448 14.9H12.056L12.7416 5.3H3.2592ZM6.4 6.9C6.61217 6.9 6.81566 6.98429 6.96569 7.13432C7.11571 7.28434 7.2 7.48783 7.2 7.7V12.5C7.2 12.7122 7.11571 12.9157 6.96569 13.0657C6.81566 13.2157 6.61217 13.3 6.4 13.3C6.18783 13.3 5.98434 13.2157 5.83431 13.0657C5.68429 12.9157 5.6 12.7122 5.6 12.5V7.7C5.6 7.48783 5.68429 7.28434 5.83431 7.13432C5.98434 6.98429 6.18783 6.9 6.4 6.9ZM9.6 6.9C9.81217 6.9 10.0157 6.98429 10.1657 7.13432C10.3157 7.28434 10.4 7.48783 10.4 7.7V12.5C10.4 12.7122 10.3157 12.9157 10.1657 13.0657C10.0157 13.2157 9.81217 13.3 9.6 13.3C9.38783 13.3 9.18434 13.2157 9.03432 13.0657C8.88429 12.9157 8.8 12.7122 8.8 12.5V7.7C8.8 7.48783 8.88429 7.28434 9.03432 7.13432C9.18434 6.98429 9.38783 6.9 9.6 6.9Z"
                                        fill="#AFAFAF" />
                                </svg>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <select name="time_service" v-model="time_service">
                                <option value="">مدة الخدمة</option>
                            </select>
                        </div>
                        <div class="col-md-6" style="margin-bottom: 32px">
                            <input type="text" name="Type_activity" v-model="Type_activity"
                                :placeholder="$t(`Type_activity`)">
                        </div>
                        <div class="col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `هل لديك رقم ضريبي؟` : `Do you have a tax
                                number?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="tax_number" id="tax_number1">
                                <label class="label-radio" for="tax_number1">{{ this.$i18n.locale == `ar` ? `نعم
                                    املك` : `I have` }}</label>
                                <input hidden type="radio" name="tax_number" id="tax_number2">
                                <label class="label-radio" for="tax_number2">{{ this.$i18n.locale == `ar` ? `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `هل لديك رقم سجل تجاري؟` : `Do you have a
                                commercial registration number?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="commercial" id="commercial1">
                                <label class="label-radio" for="commercial1">{{ this.$i18n.locale == `ar` ? `نعم
                                    املك` : `I have` }}</label>
                                <input hidden type="radio" name="commercial" id="commercial2">
                                <label class="label-radio" for="commercial2">{{ this.$i18n.locale == `ar` ? `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="index == 2">
                        <div class=" col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `كم عدد السجلات التجارية المسجلة في شهادة
                                الضريبة؟؟` : `How many business records are registered in the tax certificate??`
                                }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="business_records" id="business_records1">
                                <label class="label-radio" for="business_records1">1</label>
                                <input hidden type="radio" name="business_records" id="business_records2">
                                <label class="label-radio" for="business_records2">2</label>
                                <input hidden type="radio" name="business_records" id="business_records3">
                                <label class="label-radio" for="business_records3">3</label>
                                <input hidden type="radio" name="business_records" id="business_records4">
                                <label class="label-radio" for="business_records4">{{ this.$i18n.locale == `ar`
        ? `غير ذلك` : `Other` }}</label>
                                <input hidden type="radio" name="business_records" id="business_records5">
                                <label class="label-radio" for="business_records5">{{ this.$i18n.locale == `ar`
        ? `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `هل لديك برنامج محاسبي؟` : `Do you have
                                accounting software?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="accounting_software" id="accounting_software1">
                                <label class="label-radio" for="accounting_software1">{{ this.$i18n.locale ==
        `ar` ? `نعم
                                    املك` : `I have` }}</label>
                                <input hidden type="radio" name="accounting_software" id="accounting_software2">
                                <label class="label-radio" for="accounting_software2">{{ this.$i18n.locale ==
        `ar` ? `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `هل لديك نقاط بيع؟` : `Do you have selling
                                points?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="points" id="points1">
                                <label class="label-radio" for="points1">{{ this.$i18n.locale == `ar` ? `نعم
                                    املك` : `I have` }}</label>
                                <input hidden type="radio" name="points" id="points2">
                                <label class="label-radio" for="points2">{{ this.$i18n.locale == `ar` ? `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `هل لديك نقاط بيع مربوطة ببرنامج محاسبي؟` : `Do
                                you have points of sale linked to accounting software?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="points_linked" id="points_linked1">
                                <label class="label-radio" for="points_linked1">{{ this.$i18n.locale == `ar` ?
        `نعم
                                    املك` : `I have` }}</label>
                                <input hidden type="radio" name="points_linked" id="points_linked2">
                                <label class="label-radio" for="points_linked2">{{ this.$i18n.locale == `ar` ?
        `لا
                                    املك` : `I dont have` }}</label>
                            </div>
                        </div>
                        <div class=" col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `كم عدد فواتير المبيعات والمشتريات التقريبي
                                شهرياً؟` : `What is the approximate number of sales and purchase invoices per
                                month?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="invoices" id="invoices1">
                                <label class="label-radio" for="invoices1">101 - 200 فاتورة شهرياً</label>
                                <input hidden type="radio" name="invoices" id="invoices2">
                                <label class="label-radio" for="invoices2">101 - 200 فاتورة شهرياً</label>
                                <input hidden type="radio" name="invoices" id="invoices3">
                                <label class="label-radio" for="invoices3">201 - 400 فاتورة شهرياً</label>
                                <input hidden type="radio" name="invoices" id="invoices4">
                                <label class="label-radio" for="invoices4">401 - 800 فاتورة شهرياً</label>
                                <input hidden type="radio" name="invoices" id="invoices5">
                                <label class="label-radio" for="invoices5">اكثر من 800 فاتورة شهرياً</label>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="index == 3">
                        <div class=" col-12">
                            <h3>{{ this.$i18n.locale == `ar` ? `كم عدد العمليات اليومية على نقاط البيع؟` : `How
                                many daily operations are there at points of sale?` }}</h3>
                            <div style="text-align: start;margin-bottom: 24px;">
                                <input hidden type="radio" name="operations" id="operations1">
                                <label class="label-radio" for="operations1">اقل من 100 عملية يومياً/ 3000
                                    شهرياً </label>
                                <input hidden type="radio" name="operations" id="operations2">
                                <label class="label-radio" for="operations2">اقل من 200 عملية يومياً/ 6000
                                    شهرياً</label>
                                <input hidden type="radio" name="operations" id="operations3">
                                <label class="label-radio" for="operations3">اقل من 300 عملية يومياً/ 9000
                                    شهرياً</label>
                                <input hidden type="radio" name="operations" id="operations4">
                                <label class="label-radio" for="operations4">اكثر من ذلك</label>
                                <input hidden type="radio" name="operations" id="operations5">
                                <label class="label-radio" for="operations5">ليس لدي نقاط بيع</label>
                            </div>
                        </div>
                        <div class="col-12" style="margin-bottom: 12px;">
                            <h3 style="margin-bottom: 12px;">{{ this.$i18n.locale == `ar` ? `السجل التجاري` : `commercial
                                register` }}</h3>
                            <input hidden accept=".jpg , application/pdf" type="file" id="files1" name="files">
                            <label for="files1" class="files">
                                <svg width="39" height="32" viewBox="0 0 39 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.45645 12.6206C4.46713 13.5696 1.5 17.157 1.5 21.4375C1.5 26.4426 5.55656 30.5 10.5606 30.5C11.419 30.5 12.2495 30.3806 13.0364 30.1575M30.5435 12.6206C34.5329 13.5696 37.5 17.157 37.5 21.4375C37.5 26.4426 33.4435 30.5 28.4394 30.5C27.581 30.5 26.7505 30.3806 25.9636 30.1575M30.4939 12.375C30.4939 6.3689 25.626 1.5 19.6212 1.5C13.6164 1.5 8.74847 6.3689 8.74847 12.375M13.3382 19.5173L19.6213 13.2122L26.0832 19.6252M19.6212 28.6873V16.4184"
                                        stroke="#225476" stroke-width="3" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <div>
                                    <div>{{ this.$i18n.locale == `ar` ? `ادرج ملفاتك` : `List your files` }}</div>
                                    <div>PDF , JBG</div>
                                </div>
                            </label>
                        </div>
                        <div class="col-12" style="margin-bottom: 12px;">
                            <h3 style="margin-bottom: 12px;">{{ this.$i18n.locale == `ar` ? `شهادة تسجيل ضريبة القيمة
                                المضافة`:`VAT registration certificater` }}</h3>
                            <input hidden accept=".jpg , application/pdf" type="file" id="files2" name="files">
                            <label for="files2" class="files">
                                <svg width="39" height="32" viewBox="0 0 39 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.45645 12.6206C4.46713 13.5696 1.5 17.157 1.5 21.4375C1.5 26.4426 5.55656 30.5 10.5606 30.5C11.419 30.5 12.2495 30.3806 13.0364 30.1575M30.5435 12.6206C34.5329 13.5696 37.5 17.157 37.5 21.4375C37.5 26.4426 33.4435 30.5 28.4394 30.5C27.581 30.5 26.7505 30.3806 25.9636 30.1575M30.4939 12.375C30.4939 6.3689 25.626 1.5 19.6212 1.5C13.6164 1.5 8.74847 6.3689 8.74847 12.375M13.3382 19.5173L19.6213 13.2122L26.0832 19.6252M19.6212 28.6873V16.4184"
                                        stroke="#225476" stroke-width="3" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <div>
                                    <div>{{ this.$i18n.locale == `ar` ? `ادرج ملفاتك` : `List your files` }}</div>
                                    <div>PDF , JBG</div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row bottom">
                <div class="col-6 d-flex justify-content-start">
                    <button class="delete-services">
                        {{ this.$i18n.locale == `ar` ? `الغاء الطلب` : `Close Order` }}
                    </button>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <button class="next-step" @click="handelNextStep">
                        {{ this.$i18n.locale == `ar` ? `الخطوة التالية` : `Next Step` }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 1,
            department_service: ``,
            kind_service: ``,
            ListAdded: [],
            time_service: ``,
            Type_activity: ``
        };
    },
    methods: {
        handelAdd() {
            this.ListAdded.push(
                {
                    department: this.department_service,
                    kind: this.kind_service
                }
            );
        },
        delete(index) {
            this.ListAdded.splice(index, 1)
        },
        handelNextStep() {
            this.index+=1;
        }
    }

}
</script>
