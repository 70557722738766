<template>
    <div id="blogs">
      
        <HeadSections class="blog-img"
  :TitlePage="this.$i18n.locale == 'ar' ? 'تعرف على اخر الاخبار !':'Find out the latest news !'"
  :TitleSec="this.$i18n.locale == 'ar' ? 'كن على تواصل دائم لكل ما هو جديد.':'Stay in touch with everything new.'"
  :PathPageFrom="$t('Home')"
  :PathPageTo="$t('Blogs')" />
  <div class="container" style=" overflow: hidden;margin-top: -70px;">
    <IndexBlogs />
  </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'blogs',
components: {
  HeadSections: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Global/HeadSections.vue') ),
  IndexBlogs: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Blogs/Index.vue') ),
},
}
</script>