<template>
 <PriceOffer v-if="this.$store.state.showOfferPrice == true"  />
    <div class="row">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Service_requester') }}</h2>
                <button @click="this.$store.state.showOfferPrice = true" class="btn-main">{{ $t('Show_Price') }}</button>
                {{  }}
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('FullName') }}</div>
                <div class="value">جواد احمد راضي</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('PhoneNumber') }}</div>
                <div class="value">000 000 000</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Email') }}</div>
                <div class="value">thisisaname12@gmail.com</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Adress') }}</div>
                <div class="value">السعودية / الرياض</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Tax_Number') }}</div>
                <div class="value">1256987546958</div>
            </div>
        </div>

    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
    data() {
        return {
            index: 1,
        };
    },
    components:{
        PriceOffer: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Packages/Order/Tabs/PriceOffer.vue') ),
    }
}
</script>
