<template>
    <div class="row" v-if="company">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Service_requester') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('FullName') }}</div>
                <div class="value">{{ company.name }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('PhoneNumber') }}</div>
                <div class="value">{{ $store.state.Userdata.phone }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Email') }}</div>
                <div class="value">{{ $store.state.Userdata.email }}</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Adress') }}</div>
                <div class="value"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Tax_Number') }}</div>
                <div class="value">{{ company.tax_number }}</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
    props:{
        company:Object,
    },
}
</script>