<template>
   <div class="auth">
    <img class="cover" src="../../assets/img/auth/Cover.svg" alt="">
    <div class="container">
        <Reset />
    </div>
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Reset Page',
components: {
  Reset: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/auth/resetPassword.vue') ),
},
}
</script>

