<template>
  <div class="electronic-services">
    <div class="container">
      <div class="banner-static-services">
        <h4>
          {{ $t('Electronic government services management service in Saudi Arabia') }}
        </h4>
       <p>
        {{ $t('Electronic desc') }}
       </p>
      </div>
      <Contact />
     <div class="benefits">
      <div class="row">
        <div class="col-md-4 mb-3">
          <div class="info">
            <div class="title">
              {{ $t('Benefits of using Etmam Company in managing electronic services!') }}
            </div>
            <p>
              {{ $t('Companies in Saudi desc') }}
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Obtaining licenses and permits') }}
                </div>
                <p>
                  {{ $t('Obtaining desc') }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Technical specialization') }}
                </div>
                <p>
                  {{ $t('Technical specialization desc') }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Cost savings') }}
                </div>
                <p>
                  {{ $t('Cost savings desc') }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Navigating regulations and compliance') }}
                </div>
                <p>
                  {{ $t('Navigating regulations and compliance desc') }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Managing relations with the government') }}
                </div>
                <p>
                  {{ $t('Managing relations with the government desc') }}
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="item">
                <div class="title">
                  {{ $t('Specialized government relations official') }}
                </div>
                <p>
                  {{ $t('Specialized government relations official desc') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
     <div class="banner-2">
      <div class="row">
        <div class="col-md-6 mb-3">
          <img class="w-100" src="@/assets/img/services-page/elc-banner-2.png" alt="">
        </div>
        <div class="col-md-6" style="display: grid;place-content: center;">
            <div class="title">
              {{ $t('Direct support service for government ministries’ electronic problems') }}
            </div>
            <p>
              {{ $t('Direct desc') }}
            </p>
        </div>
      </div>
     </div>
     <div class="what-we-offer">
      <div class="title">
        {{ $t('What we offer you !') }}
      </div>
      <p class="w8">
        {{ $t('Etmaam Services provides a wide range of services amounting to more than 500 services that establishments need within the Kingdom of Saudi Arabia. Whether through its team or through partnership with Etmam partners, it has a team and qualified partners to complete the services with high accuracy and in a short time.') }}
      </p>
      <div class="d-flex align-items-center justify-content-center mt-5 img-services">
        <img @click="index = 1" :class="[index == 1 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-1.png" alt="">
        <img @click="index = 2" :class="[index == 2 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-2.png" alt="">
        <img @click="index = 3" :class="[index == 3 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-3.png" alt="">
        <img @click="index = 4" :class="[index == 4 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-4.png" alt="">
        <img @click="index = 5" :class="[index == 5 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-5.png" alt="">
        <img @click="index = 6" :class="[index == 6 ? 'active':'']" width="60" src="@/assets/img/Icon/Rectangle-6.png" alt="">
      </div>
      <div class="info mt-3" v-if="index == 1">
        <div class="title">
          {{ $t('Ministry of Commerce services') }}
        </div>
        <p>
        {{ $t('Ministry of Commerce services desc') }}
        </p>
      </div>
      <div class="info mt-3" v-if="index == 2">
        <div class="title">
          {{ $t('Ministry of Energy, Industry and Mineral Resources') }}
        </div>
        <p>
        {{ $t('Ministry of Energy, Industry and Mineral Resources desc') }}
        </p>
      </div>
      <div class="info mt-3" v-if="index == 3">
        <div class="title">
          {{ $t('General Organization for Social Insurance') }}
        </div>
        <p>
        {{ $t('General Organization for Social Insurance desc') }}
        </p>
      </div>
      <div class="info mt-3" v-if="index == 4">
        <div class="title">
          {{ $t('Ministry of Human Resources') }}
        </div>
        <p>
        {{ $t('Ministry of Human Resources desc') }}
        </p>
      </div>
      <div class="info mt-3" v-if="index == 5">
        <div class="title">
          {{ $t('Ministry of Interior (Absher - Resident)') }}
        </div>
        <p>
        {{ $t('Ministry of Interior (Absher - Resident) desc') }}
        </p>
      </div>
      <div class="info mt-3" v-if="index == 6">
        <div class="title">
          {{ $t('General Authority of Zakat and Income') }}
        </div>
        <p>
        {{ $t('General Authority of Zakat and Income desc') }}
        </p>
      </div>
     </div>
    </div>
   
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Contact: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'./component/contact-services.vue')),
  },
  data() {
    return {
      index:1,
    };
  },
  methods:{
  },
  mounted() {
  }

}
</script>