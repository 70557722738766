<template>
    <div class="m-p">
       <Support />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Support Center',
 components: {
    Support: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/Support/Support.vue') ),
 },
 }
 </script>