<template>
    <div id="map" style="height: 320px"></div>
  </template>
  
  <script>
  export default {
    name: 'GoogleMap',
    data() {
      return {
        map: null,
        marker: null,
        latitude: null,
        longitude: null,
        city: null
      }
    },
    mounted() {
      this.initMap()
    },
    methods: {
      initMap() {
        this.map = new google.maps.Map(document.getElementById('map'), {
          center: { lat:24.81513210530801, lng: 46.67082131277138 },
          zoom: 10
        })
  
        this.marker = new google.maps.Marker({
          map: this.map,
          draggable: true
        })
  
        this.map.addListener('click', (event) => {
          this.updateMarkerPosition(event.latLng)
        })
  
        this.marker.addListener('dragend', () => {
          this.updateMarkerPosition(this.marker.getPosition())
        })
      },
      updateMarkerPosition(latLng) {
        this.latitude = latLng.lat()
        this.longitude = latLng.lng()
        this.marker.setPosition(latLng)
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${this.latitude}&lon=${this.longitude}&zoom=18&addressdetails=1`
        fetch(url)
        .then(response => response.json())
        .then(data => {
          this.city = data.address.town || data.address.city  || data.address.village || data.address.state
          this.$emit('my-event', { latitude:this.latitude, longitude: this.longitude, city:this.city });

        })
        .catch(error => {
          console.error('Error getting city:', error)
        })

      },
 
    }
  }
  </script>