<template>
    <div class="row ">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Payment') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
       <div class="payment-pending">
        <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 9.16658C16.0444 9.16658 12.1776 10.3396 8.8886 12.5372C5.59962 14.7348 3.03617 17.8584 1.52242 21.5129C0.00866572 25.1674 -0.387401 29.1888 0.384303 33.0684C1.15601 36.948 3.06082 40.5117 5.85787 43.3087C8.65492 46.1058 12.2186 48.0106 16.0982 48.7823C19.9778 49.554 23.9992 49.1579 27.6537 47.6442C31.3082 46.1304 34.4318 43.567 36.6294 40.278C38.827 36.989 40 33.1222 40 29.1666C39.9939 23.8641 37.8849 18.7806 34.1354 15.0311C30.386 11.2817 25.3025 9.17265 20 9.16658ZM20 44.1666C17.0333 44.1666 14.1332 43.2868 11.6665 41.6386C9.19972 39.9904 7.27713 37.6477 6.14181 34.9068C5.0065 32.1659 4.70945 29.1499 5.28823 26.2402C5.86701 23.3305 7.29562 20.6578 9.39341 18.56C11.4912 16.4622 14.1639 15.0336 17.0737 14.4548C19.9834 13.876 22.9994 14.1731 25.7403 15.3084C28.4811 16.4437 30.8238 18.3663 32.472 20.833C34.1203 23.2998 35 26.1999 35 29.1666C34.9956 33.1435 33.4138 36.9562 30.6017 39.7683C27.7896 42.5804 23.9769 44.1622 20 44.1666ZM27.6021 20.7312C27.8352 20.9634 28.0201 21.2394 28.1463 21.5433C28.2724 21.8472 28.3374 22.173 28.3374 22.502C28.3374 22.831 28.2724 23.1568 28.1463 23.4607C28.0201 23.7646 27.8352 24.0406 27.6021 24.2728L21.7688 30.1062C21.2991 30.5758 20.6621 30.8397 19.9979 30.8397C19.3337 30.8397 18.6967 30.5758 18.2271 30.1062C17.7574 29.6365 17.4936 28.9995 17.4936 28.3353C17.4936 27.6711 17.7574 27.0342 18.2271 26.5645L24.0604 20.7312C24.2927 20.4981 24.5687 20.3132 24.8725 20.187C25.1764 20.0608 25.5022 19.9959 25.8313 19.9959C26.1603 19.9959 26.4861 20.0608 26.79 20.187C27.0938 20.3132 27.3698 20.4981 27.6021 20.7312ZM12.5 3.33325C12.5 2.67021 12.7634 2.03433 13.2322 1.56548C13.7011 1.09664 14.337 0.833252 15 0.833252H25C25.663 0.833252 26.2989 1.09664 26.7678 1.56548C27.2366 2.03433 27.5 2.67021 27.5 3.33325C27.5 3.99629 27.2366 4.63218 26.7678 5.10102C26.2989 5.56986 25.663 5.83325 25 5.83325H15C14.337 5.83325 13.7011 5.56986 13.2322 5.10102C12.7634 4.63218 12.5 3.99629 12.5 3.33325Z" fill="#225476"/>
        </svg>
        <p>
          {{ 
          this.$i18n.locale == 'ar' ?
          `يؤجل الدفع لحين الانتهاء من دراسة الطلب و الموافقة عليه من قبل فريق اتمام`
          :
          `Payment is postponed until the application is studied and approved by the completion team`  
          }}
        </p>
       </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      imageId:[],
    }
  },
  methods:{
    SetimageId(e){
      console.log(event.target.files);
      this.imageId.push(event.target.files[0])
    },
    downloadFile(file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      URL.revokeObjectURL(url);
    },
  }

};
</script>