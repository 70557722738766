<template>
    <div class="row">
        <div class="col-12">
            <div class="title-nav">
                <h2>{{ $t('Server_Provider') }}</h2>
                <button class="btn-main">{{ $t('Show_Price') }}</button>
            </div>
        </div>
        <div class="col-12">
            <div class="provider">
                <img src="@/assets/img/Icon/provider.png" alt="">
                <h3 class="title">منصة بلدي </h3>
            </div>
            <p>{{ $t('TextExample') }}</p>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('FullName') }}</div>
                <div class="value">جواد احمد راضي</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('PhoneNumber') }}</div>
                <div class="value">000 000 000</div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="item">
                <div class="title">{{ $t('Email') }}</div>
                <div class="value">thisisaname12@gmail.com</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 1,
        };
    },
}
</script>