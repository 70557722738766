<template>
    <div class="login-card">
      <div class="row">
        <div class="col-md-6 d-md-block d-none">
          <img class="w-100" src="../../assets/img/auth/login-backgroun.png" alt="">
          <div class="etmaam">
            <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
            <p>
              {{
                this.$i18n.locale == 'ar' ?
                  `استفد من خبرتنا واستمتع بخدمات متميزة تلبي احتياجاتك بدقة واحترافية.`
                  :
                  `Benefit from our experience and enjoy distinguished services that meet your needs accurately and
              professionally.`
  
              }}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <Form class="auth-form" @submit="HandelForgot">
            <div class="row">
              <div class="col-12">
                <h1 class="title">{{ $t('Forgot') }}</h1>
              </div>
              <div class="col-12 field">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.85077 17.8343C2.25742 17.8441 1.68096 17.6366 1.23003 17.2508C1.03033 17.0756 0.870029 16.8601 0.759682 16.6185C0.649334 16.3768 0.59145 16.1145 0.589844 15.8489V2.1537C0.59347 1.88682 0.653881 1.62377 0.767058 1.38204C0.880235 1.14032 1.04358 0.925463 1.24624 0.751767C1.69717 0.365988 2.27363 0.158463 2.86697 0.168303H11.1327C11.7261 0.158463 12.3025 0.365988 12.7534 0.751767C12.9561 0.925463 13.1195 1.14032 13.2326 1.38204C13.3458 1.62377 13.4062 1.88682 13.4098 2.1537V15.8489C13.4042 16.1154 13.3429 16.3778 13.2298 16.6192C13.1168 16.8607 12.9545 17.0758 12.7534 17.2508C12.3025 17.6366 11.7261 17.8441 11.1327 17.8343H2.85077ZM2.85077 1.27851C2.60485 1.26522 2.36367 1.34982 2.17994 1.51381C1.9962 1.67781 1.88486 1.90786 1.87022 2.1537V15.8489C1.88486 16.0947 1.9962 16.3248 2.17994 16.4888C2.36367 16.6528 2.60485 16.7374 2.85077 16.7241H11.1327C11.3786 16.7374 11.6198 16.6528 11.8035 16.4888C11.9873 16.3248 12.0986 16.0947 12.1133 15.8489V2.1537C12.0986 1.90786 11.9873 1.67781 11.8035 1.51381C11.6198 1.34982 11.3786 1.26522 11.1327 1.27851H2.85077ZM5.54929 15.2897C5.38253 15.2891 5.22152 15.2288 5.09548 15.1196C5.03184 15.0729 4.98008 15.0119 4.94441 14.9415C4.90873 14.8711 4.89014 14.7933 4.89014 14.7144C4.89014 14.6355 4.90873 14.5577 4.94441 14.4873C4.98008 14.4169 5.03184 14.3559 5.09548 14.3092C5.2223 14.2023 5.38344 14.1448 5.54929 14.1471H8.43419C8.60004 14.1448 8.76118 14.2023 8.888 14.3092C8.95164 14.3559 9.0034 14.4169 9.03907 14.4873C9.07475 14.5577 9.09334 14.6355 9.09334 14.7144C9.09334 14.7933 9.07475 14.8711 9.03907 14.9415C9.0034 15.0119 8.95164 15.0729 8.888 15.1196C8.76196 15.2288 8.60095 15.2891 8.43419 15.2897H5.54929Z"
                    fill="#0A3041" />
                </svg>
                <input id="phone" type="tel" name="phone">
                 <p id="output" class="error-message mt-1 mb-1">
                 </p>  
              </div>
              <div class="col-12">
                <button class="btn-main">{{$t("Confirm")}}</button>
              </div>
              <div class="col-12 d-flex align-items-center justify-content-between ">
                <router-link to="/login" class="btn-third">
                  {{ $t('Login') }}
                </router-link>
              </div>
            </div>
          </Form>
          <Verification v-if="ShowVerification" :PhoneNumber="PhoneNumber" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import Verification from './Verification_Reset.vue';
  import axios from 'axios';
  import { notify } from "@kyvg/vue3-notification";
  // import vee-validate 
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';
  import { configure } from "vee-validate";
  import Cookie from 'cookie-universal'
const cookie = Cookie()
import intlTelInput from "../../intl-tel-input.js"
  export default {
    data() {
      return {
        ShowVerification:false,
      FullNumber: '',
      }
    },
    components: {
      Form,
      Field,
      ErrorMessage,
      Verification
    },
    methods: {
      async HandelForgot(values) {
        if(this.FullNumber){
          const formData = {
          "phone": this.FullNumber,
        }
          await axios.post(`/user/auth/request-reset-password-code`, formData)
          .then((res) => {
            if(res.status == 200){
                this.ShowVerification = true;
                this.PhoneNumber = values.phone;
              }
              
          })
          .catch(function (error) {
            if(error){
                notify({
                type: "error",
                title: "خـطـأ !!",
                text:error.response?.data?.message,
              });
            }
          });
        }
      
      }
    },
    mounted() {
    const output = document.querySelector("#output");
    const inputElement = document.querySelector('#phone');

    const iti = intlTelInput(inputElement, {  i18n: {
    searchPlaceholder: this.$i18n.locale == 'ar' ? 'بحث':'Search',
    },
      initialCountry: "auto",
      nationalMode: true,
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("us"));
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js"
    });

    const handleChange = () => {
      let text;
      if (this.$i18n.locale == 'ar') {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء اعد المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      }
      else{
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
          else{
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('keyup', handleChange);


  }
  }
  </script>