<template>
    <div id="preloader">
        <div class="loader revolve">
            <div id="wppu-object-wrapper" class="lifebeauty" style="width:500px;height:500px;padding:13%;">
                <div class="wppu-object-logo">
                    <img src="@/assets/img/global/loading.png" class="officallogo">
                </div>
                <div class="wpppu-object-wrap" style="color:#235577;"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
