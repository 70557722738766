<template>
    <div class="partner">
        <TitleSection :title="$t('PartnerTitle')" :TextAlign="`center`" />
        <swiper class="swiper pb-5" 
        :speed="8000"
        :loop="true"
        :autoplay="{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection:true
        }" 
        :breakpoints="swiperOptions.breakpoints">
            <swiper-slide class="item" v-for="item in Partners.slice(0,8)" :key="item.id">
                <img class="w-100" @error="onImageLoadFailure($event)" :src="item.image" alt="">
            </swiper-slide>
        </swiper>
        <swiper class="swiper" :space-between="20" 
        :speed="8000"
        :loop="true"
        :autoplay="{
            delay: 0,
            disableOnInteraction: false,
        }"  
        :breakpoints="swiperOptions.breakpoints">
            <swiper-slide class="item" v-for="item in Partners.slice(8,Partners.length)" :key="item.id">
                <img class="w-100" @error="onImageLoadFailure($event)" :src="item.image" alt="">
            </swiper-slide>
          
        </swiper>
    </div>

</template>
<script>
import { defineAsyncComponent } from 'vue'
// Import Swiper Vue.js components
export default {
    data() {
        return {
            swiperOptions: {
                breakpoints: {

                    300: {  // when window width from 300px to 576px
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    576: {  // when window width from 576px to 767px
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    767: { // when window width from 767px to 991px
                        slidesPerView: 3,
                        spaceBetween: 20
                    },

                    991: { // when window width from 991px to 1200px
                        slidesPerView: 4,
                        spaceBetween: 20
                    },
                    1200: { // when window width from 1200px to higher
                        slidesPerView: 5,
                        spaceBetween: 20
                    },
                }
            }
        }
    },
    components: {
        TitleSection: defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Global/TitleSection.vue')),
    },
    methods: {
      onImageLoadFailure (event) {
        event.target.src = '@/assets/img/global/Logo1.png'    
      }
    },
    computed:{
        Partners(){
            return this.$store.state.Partners
        }
    },
    mounted(){
        this.$store.dispatch('GetPartners');
    }
};
</script>